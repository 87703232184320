import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ThankYou = () => {
    let navigate = useNavigate()
    let [timer, setTimer] = useState(4)
    useEffect(() => {
        setTimeout(() => {
            navigate(-1)
        }, 4000);
        setInterval(() => {
            setTimer((prev) => prev - 1)
        }, 1000);
    }, [])
    useEffect(() => {
        // Create script element
        const script1 = document.createElement("script");
        script1.async = true;
        script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-11378420685";
        document.head.appendChild(script1);

        // Create inline script
        const script2 = document.createElement("script");
        script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11378420685');
        `;
        document.head.appendChild(script2);

        return () => {
            // Cleanup when the component unmounts
            document.head.removeChild(script1);
            document.head.removeChild(script2);
        };
    }, []);
    return (
        <div className='flex h-[100vh] bgsec' >
            <main className='text-center flex flex-col justify-center rounded p-3 min-h-[60vh] container mx-auto shadow bg-white m-auto ' >
                <h4 className='text-[70px] lg:text-[120px] tracking-wide poppins fw-semibold ' > Thank <br /> You!! </h4>
                <p className='poppins fw-medium my-3 ' > Will redirect to the previous page in {timer} sec </p>
            </main>
        </div>
    )
}

export default ThankYou