import React from 'react'

const LinkedIn2Icon = ({ size }) => {
    return (
        <svg width={size ? size : "37"} height={size ? size : "37"} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="white" stroke-width="1.5" />
            <path d="M14.4835 14.9671C15.3029 14.9671 15.9671 14.3029 15.9671 13.4835C15.9671 12.6642 15.3029 12 14.4835 12C13.6642 12 13 12.6642 13 13.4835C13 14.3029 13.6642 14.9671 14.4835 14.9671Z" fill="white" />
            <path d="M15.7198 15.957H13.2473C13.1108 15.957 13 16.0678 13 16.2043V23.622C13 23.7585 13.1108 23.8693 13.2473 23.8693H15.7198C15.8563 23.8693 15.9671 23.7585 15.9671 23.622V16.2043C15.9671 16.0678 15.8563 15.957 15.7198 15.957Z" fill="white" />
            <path d="M23.0876 15.5464C22.0308 15.1844 20.7089 15.5024 19.9162 16.0726C19.889 15.9662 19.7921 15.8871 19.6769 15.8871H17.2043C17.0678 15.8871 16.957 15.9979 16.957 16.1344V23.5521C16.957 23.6886 17.0678 23.7994 17.2043 23.7994H19.6769C19.8134 23.7994 19.9241 23.6886 19.9241 23.5521V18.2212C20.3237 17.8771 20.8385 17.7673 21.2598 17.9463C21.6683 18.1189 21.9022 18.5402 21.9022 19.1015V23.5521C21.9022 23.6886 22.013 23.7994 22.1495 23.7994H24.622C24.7585 23.7994 24.8693 23.6886 24.8693 23.5521V18.6035C24.8411 16.5715 23.8852 15.8194 23.0876 15.5464Z" fill="white" />
        </svg>

    )
}

export default LinkedIn2Icon