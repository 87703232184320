import React, { useEffect, useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'
import NavBar from '../Component/NavBar'
import BannerTCR from '../Component/TCR/BannerTCR'
import DesignationWeAreHiring from '../Component/TCR/DesignationWeAreHiring'
import ExclusiveFeature from '../Component/TCR/ExclusiveFeature'
import HowItWorks from '../Component/TCR/HowItWorks'
import TrainingJuorney from '../Component/TCR/TrainingJuorney'
import TestimonialTCR from '../Component/TCR/TestimonialTCR'
import HeadingTag from '../Component/MiniCom/HeadingTag'
import NewFooter from '../Component/TCR/NewFooter'

const TraningComeRequirtment = () => {
  let [activeCircle, setActiveCircle] = useState(0)
  let data = [
    {
      heading: 'High School Graduates : ',
      para: `Start your career early by participating in Employee Training Programs that guide your future educational and professional paths.`
    },
    {
      heading: 'College Students :',
      para: `Enrich your academic journey with Training Workshops that offer practical skills and real-world applications, bridging the gap between classroom learning and professional 
      experience.`
    },
    {
      heading: ' Aspiring Professionals :  ',
      para: `Develop your expertise and credentials in your field with our Recruitment Strategies to stand out in the job market and achieve your career 
      ambitions.`
    },
    {
      heading: 'Career Changers :  ',
      para: `Explore new fields with our Corporate Training Solutions and gain certifications that facilitate a smooth transition into a new career path.`
    },
    {
      heading: 'Working Professionals :  ',
      para: `Upgrade your skills through Dual Certification and gain credentials that open doors to new opportunities and career advancement within your current field or
       beyond.`
    }, {
      heading: 'Educators and Trainers :',
      para: `Integrate our Training and Recruitment programs into your curriculum or professional development plans to provide students with valuable industry-recognized
       credentials.`
    }, {
      heading: 'Tech Enthusiasts  : ',
      para: `Dive into the latest technologies with our training solutions that validate your expertise and enhance your application of cutting-edge
       innovations.`
    },
  ]
  let sixPoints = [
    {
      heading: ' Personalized Training Plan',
      short: '1: Training',
      para: `We assess your skills and interests to create a training plan that aligns perfectly with your career 
      goals.`
    },
    {
      heading: 'Industry-Relevant Skills',
      short: '2: Skills ',
      para: `We provide training that aligns with industry standards, ensuring you gain the skills employers are actively 
      seeking.`
    },
    {
      heading: 'Hands-On Learning',
      short: '3: Learning ',
      para: `Learn by doing through our triangle model: theory, practical tasks, and feedback to ensure you master 
      new skills. `
    },
    {
      heading: 'Resume Building',
      short: '4: Resume',
      para: `We assist in creating professional resumes, including video resumes, that showcase your skills and strengths
       effectively.`
    },
    {
      heading: 'Interview Preparation',
      short: '5: Interview',
      para: `Receive expert coaching and take mock interviews to build your confidence, ensuring you’re fully prepared for any upcoming 
      interviews.`
    },
    {
      heading: 'Placement Assisstance ',
      short: '6: Placement',
      para: `We connect you with top companies, offering support to secure job opportunities that match your skills and 
      interests.`
    },
  ]
  let whyData = [
    {
      heading: 'Effective Recruitment Strategies : ',
      para: `Master the best practices for onboarding new employees to ensure their seamless and effective integration into your 
      organization.`
    },
    {
      heading: 'Industry-Recognized Certifications :',
      para: `Enhance your resume with credentials that highlight your skills and set you apart in the job market. Our certifications validate your proficiency in training and 
      recruitment. `
    },
    {
      heading: 'Practical Experience :  ',
      para: `Engage in practical projects and real-life scenarios tailored for hands-on learning. This practical approach prepares you to tackle industry challenges and apply what you've learned 
      effectively. `
    },
    {
      heading: 'Expand Your Professional Network :  ',
      para: `Connect with industry leaders and peers, expanding your professional network and opening doors to future career opportunities. `
    },
    {
      heading: 'Develop Both Technical and Soft Skills : ',
      para: `Build your expertise in Employee Training Programs while also refining essential soft skills like communication and problem-solving. This balanced skill set prepares you for success in
       any professional setting.`
    },
    {
      heading: 'Job-Ready Status : ',
      para: `Ensure you are prepared for the professional world with our structured programs. Our training equips you with the skills and certifications that demonstrate your capabilities and readiness for the job 
      market.`
    },
    {
      heading: 'Flexible Learning Experience : ',
      para: `Benefit from our Hybrid Classes that offer the flexibility of online learning combined with the interactive structure of in-person sessions. This format accommodates your schedule while delivering in-depth 
      knowledge.`
    },
    {
      heading: 'Capstone Projects : ',
      para: `Showcase your skills through projects that reflect real-world applications, enhancing your portfolio and job 
      readiness. `
    },
  ]
  let designation = [
    {
      img: '../Assest/cloudComputingTCR.png',
      title: 'Cloud Computing',
      content: `Learn to work with AWS, Google Cloud, and Azure to build and manage secure, scalable cloud infrastructure for businesses.`
    },
    {
      img: '../Assest/DataanalyticsTcr.png',
      title: 'Data Analytics',
      content: ` Our training covers tools like Tableau, Power BI, Python, and SQL, helping you turn raw data into actionable insights.`
    },
    {
      img: '../Assest/cyberSecurityTcr.png',
      title: 'Cyber Security',
      content: `Gain hands-on experience in ethical hacking and penetration testing, preparing you to protect systems and networks from cyber threats.`
    },
    {
      img: '../Assest/DataScienceTCR.png',
      title: 'Data Science',
      content: ` We train you in Machine learning and AI, equipping you with the skills to analyze and interpret complex data sets`
    },
    {
      img: '../Assest/devopsTCR.png',
      title: 'DevOps',
      content: `Our DevOps training focuses on integrating development and operations, using AWS, Azure, and Google Cloud to enhance workflow efficiency.`
    },
  ]
  useEffect(() => {
    // window.scrollTo(0, 0)
  }, [])
  return (
    <div className='min-h-[50vh] bgsec' >
      {/* <NavBar theme='dark' /> */}
      <>
        <Helmet>
          <title>Latest Tech News & Insights - Read Our Blog | TechTalk </title>
          <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
          <link rel="canonical" href={`${domain}/traning-come-recruitment`} />
        </Helmet>
        <h1 className='d-none'>informative blog content </h1>
        <h2 className='d-none'>best blogging tips and tricks </h2>
      </>
      <BannerTCR options={designation.map((obj) => ({ value: obj.title, label: obj.title }))} />
      <DesignationWeAreHiring data={designation} />
      <ExclusiveFeature />
      <HowItWorks />
      <TrainingJuorney />
      <TestimonialTCR page
        content={`Hear from our students who have joined our program, worked with our client companies and are now successful in their new roles`} />
      <main className=' py-5  ' >
        <h5 className=' fw-semibold text-2xl lg:text-3xl w-fit mx-auto ' >
          <HeadingTag txt='About Us' clr='4169FF' />
        </h5>
        <section className='poppins px-2 col-12 col-sm-11 mx-auto text-center ' >
          <p className=' my-3 ' >
            At Merida Skill Learning Academy, we recognize the growing demand for skilled professionals who can seamlessly integrate into real work environments. Our Training & Recruitment Program is designed to equip individuals with the right expertise while providing hands-on experience in live client projects.  Unlike traditional training programs, our approach
            ensures that participants learn and work simultaneously, gaining real-world exposure from day one.
          </p>
          <p className=' my-3 ' >
            We are part of the Merida group, which also includes Merida Tech Minds, our creative branch that offers cutting-edge tech solutions and Merida HR Consulting which focuses on smart talent management. We offer a unique ecosystem that blends training with direct industry placement. With years of expertise, a strong network of hiring partners, and a commitment to
            career growth, we help individuals secure rewarding, high-growth opportunities by placing them in top companies.
          </p>
        </section>
      </main>
      <NewFooter />
    </div>
  )
}

export default TraningComeRequirtment