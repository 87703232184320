import React, { useEffect, useState } from 'react'
import HeadingTag from '../MiniCom/HeadingTag'
import CircleFillIcon from '../../Icons/CircleFillIcon'

const TrainingJuorney = ({ data }) => {
    let [selectedId, setSelectedId] = useState(0)
    let [content, setContent] = useState([
        {
            img: '../../Assest/identifySet-min.png',
            title: 'Identifying Your Strengths',
            content: `Our expert counselors assess your skills & match you with the best-fit job roles `
        },
        {
            img: '../../Assest/buildstrongfoundation-min.png',
            title: 'Build a Strong Foundation',
            points: [
                'Learn from industry-approved training programs tailored to client needs',
                'Get a perfect balance of theory & practical learning'
            ]
        },
        {
            img: '../../Assest/gainhands-min.png',
            title: 'Gain Hands-On Work Experience',
            points: [
                'Work on live projects directly for our clients',
                'Apply skills in real scenarios under expert guidance',
                'Receive real-time feedback from industry experts'
            ]
        },
        {
            img: '../../Assest/devopsCard.png   ',
            title: 'Develop In-Demand Skills ',
            points: [
                'Get trained on industry-relevant tools & technologies',
                'Learn workplace communication & collaboration best practices',
                'Develop problem-solving & analytical thinking skills',
                'Work on case studies & simulations to tackle real-world challenges'
            ]
        },
        {
            img: '../../Assest/strongPortfolio-min.png',
            title: 'Create a Strong Portfolio',
            points: [
                'Showcase your best work from real-world projects',
                'Build an ATS-friendly resume',
                'Get technical interview training with expert feedback',
                'Improve communication & soft skills for workplace success'
            ]
        },
        {
            img: '../../Assest/transitionstrongrole-min.png',
            title: 'Transition to a Permanent Role',
            points: [
                'Exclusive job opportunities with top recruiters',
                'Placement support for direct company connections',
                `Mock interviews to sharpen your confidence`,
                `Career mentorship for long-term success`,
                `Secure a Full-time role & Earn Big!`
            ]
        },
    ])
    useEffect(() => {
        if (data)
            setContent(data)
    }, [data])
    return (
        <div className='poppins bg-white py-4 ' >
            <h4 className='w-fit fw-semibold mx-auto text-3xl my-4' >
                <HeadingTag txt={`Your Training Journey in ${content.length} Simple Stages`} clr='4169FF' />
            </h4>
            <main className='px-2 gap-4 my-3 scrollhide overflow-x-scroll flex col-12 col-sm-11 mx-auto ' >
                {
                    content.map((obj, index) => (
                        <section
                            // onMouseEnter={() => setSelectedId(index)}
                            onClick={() => setSelectedId(index)}
                            className={`row  h-[50vh]  ${selectedId == index ? 'bg-[#EDEDED] p-3 flex-1 min-w-[20rem] lg:min-w-[30rem] ' : " min-w-[4rem] flex-[0.3] "}
                        duration-500 rounded-xl mx-1 `} >
                            <div className={` ${selectedId == index ? "col-xl-6 h-[40%] xl:h-[100%]  " : 'h-full'}  px-0 relative `} >
                                <img src={obj.img} alt="Image"
                                    className={` ${selectedId == index ? '  h-full ' : 'h-full'} rounded-xl px-0 w-full object-cover `} />
                                <div className={` ${selectedId == index ? 'opacity-0' : ''} absolute top-0 rounded-xl bg-gradient-to-r p-2 from-slate-50/0 to-slate-950/80 h-full w-full  `}>
                                    <div className=' h-full w-full flex ' >
                                        <p className='vertical-text mt-auto ms-auto text-slate-50 fw-semibold  ' >  {obj.title} </p>
                                    </div>
                                </div>
                            </div>
                            {selectedId == index && <div
                                className='col-xl-6 p-3 flex scrollhide overflow-y-scroll h-[50%] xl:h-full' >
                                <div className='my-auto text-[#464646] ' >
                                    <h5 className={` text-[#464646] fw-semibold my-2 text-md  `} >{obj.title}  </h5>
                                    <p className=' text-sm ' > {obj.content} </p>
                                    <main className='  ' >
                                        {
                                            obj.points?.map((val) => (
                                                <div className={`flex gap-2 items-start text-sm text-[#4169FF] `} >
                                                    <div className='h-fit mt-1' >
                                                        <CircleFillIcon size={7} />
                                                    </div>
                                                    <p className='mb-1 text-[#464646] ' > {val} </p>
                                                </div>
                                            ))
                                        }
                                    </main>
                                </div>
                            </div>}
                        </section>
                    ))
                }
            </main>
        </div >
    )
}

export default TrainingJuorney