import '../src/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import NavBar from './Component/NavBar';
import './Css/home.css'
import './Css/course.css'
import './Css/banner.css'
import './Css/font.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ParticularCourse from './Pages/ParticularCourse';
import AllCourses from './Pages/AllCourses';
import Footer from './Component/Footer';
import WhySLA from './Pages/WhySLA';
import Blog from './Pages/Blog';
import ReactGA from "react-ga4";
import ParticularBlog from './Pages/ParticularBlog';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import NotFoundPage from './Pages/NotFoundPage';
import CorporateTraining from './Pages/CorporateTraining';
import EventPages from './Pages/EventPages';
import ContactPage from './Pages/ContactUs';
import CategoryPage from './Pages/CategoryPage';
import ParticularEvent from './Pages/ParticularEvent';
import './Css/grd.css'
import './Css/slider.css'
import './Css/scrollBar.css'
import CertificateCoursePage from './Pages/CertificateCoursePage';
import OnJobTraningPage from './Pages/OnJobTraningPage';
import InternshipTraning from './Pages/InternshipTraning';
import TraningComeRequirtment from './Pages/TraningComeRequirtment';
import InterviewSkills from './Pages/InterviewSkills';
import IndustrialVisit from './Pages/IndustrialVisit';
import InplantTraining from './Pages/InplantTraining';
import { useEffect } from 'react';
import TermsAndCondition from './Pages/TermsandCondition';
import Disclamiration from './Pages/Disclamiration';
import WhatsappIcon from './Component/AboutComponent/WhatsappIcon';
import ThankYou from './Pages/ThankYou';

export const hostname = `https://backendapi.skilllearningacademy.com/`
// export const hostname = `http://192.168.18.17:9001/`
export const domain = `https://www.skilllearningacademy.com`
function App() {
  const TRACKING_ID = "AW-11378420685";
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // ReactGA.pageview(window.location.pathname + window.location.search)
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Custom Title",
    });
  }, []);
  return (
    <div className="">

      <BrowserRouter>
        {/* <NavBar /> */}
        <WhatsappIcon />
        <ToastContainer autoClose={3000} position='top-center' />

        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/notFound' element={<NotFoundPage />} />
          {/* <Route path='/view/course' element={<ParticularCourse />} /> */}
          <Route path='/:slug' element={<ParticularCourse />} />

          <Route path='/allcourse' element={<AllCourses />} />
          <Route path='/why-skilllearningacademy' element={<WhySLA />} />
          <Route path='/blogs/*' element={<Blog />} />
          <Route path='/blogs/:slug' element={<ParticularBlog />} />
          <Route path='/corporate-training' element={<CorporateTraining />} />
          <Route path='/events/*' element={<EventPages />} />
          <Route path='/events/:slug' element={<ParticularEvent />} />

          <Route path='/contact-us' element={<ContactPage />} />
          <Route path='/category/:slug' element={<CategoryPage />} />

          <Route path='/certificateCourse' element={<CertificateCoursePage />} />
          <Route path='/on-job-training' element={<OnJobTraningPage />} />
          <Route path='/internship-training' element={<InternshipTraning />} />
          <Route path='/training-cum-recruitment' element={<TraningComeRequirtment />} />
          <Route path='/thank-you' element={<ThankYou/>} />


          <Route path='/terms-and-condition' element={<TermsAndCondition />} />
          <Route path='/disclaimer' element={<Disclamiration />} />

          <Route path='/interview-skills' element={<InterviewSkills />} />
          <Route path='/industrial-visit' element={<IndustrialVisit />} />
          <Route path='/inplant-training' element={<InplantTraining />} />



        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
