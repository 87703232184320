import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CropCircle2 = (props) => {
    let { activeCircle, setActiveCircle, whyChooseData, data, scroll } = props
    let navigate = useNavigate()
    let scrollto = () => {
        if (scroll) {
            let contentDiv = document.getElementById(scroll)
            contentDiv.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        else {
            let contentDiv = document.getElementById('content')
            contentDiv.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }
    return (
        <div className='m-auto relative h-[42rem] w-[43rem] flex bg--600 ' >
            <section className='flex bg--100 m-auto relative ' >
                <img onClick={() => navigate('/why-skilllearningacademy')} src={require('../../Assest/cropcircle.png')} alt="CropCircle"
                    className='cursor-pointer w-[6rem] h-[6rem] sm:h-[11rem] sm:w-[11rem] m-auto  relative z-10 ' />
            </section>
            {/* top */}
            <div onClick={() => { setActiveCircle(0); scrollto() }}
                className={`${activeCircle == 0 && 'scale-[1.06] -translate-y-2 '} 
                cursor-pointer duration-300 hover:scale-[1.06] xl:top-10 xl:right-1/2 
                sm:top-20 sm:right-1/2 
                top-40 right-1/2
                translate-x-1/2 
            flex  absolute w-[7rem] h-[7rem] 
            sm:w-[11rem] sm:h-[11rem] xl:w-[13rem] xl:h-[13rem]`} >
                <img className='w-full absolute -bottom-5 ' src={require('../../Assest/tophexaa.webp')} alt="TopHexa" />
                <p className='absolute d-none d-sm-block top-8 text-white fw-semibold text-sm left-1/2 -translate-x-1/2 ' >
                    {whyChooseData ? whyChooseData[0].short : "Overview"} </p>
                {!whyChooseData &&
                    <p className='absolute text-sm -top-14 sm:-top-28 line-clamp-3 sm:px-3'>
                        {data?.Overview && data.Overview[0]?.point ? <span dangerouslySetInnerHTML={{ __html: data.Overview[0].point }} ></span> :
                            `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis ducimus possimus debitis molestias odio, sit dolorum accusamus autem vel dolorem.
                    `}
                    </p>}
                {whyChooseData &&
                    <p className='absolute text-sm -top-14 sm:-top-24 line-clamp-2 sm:px-3'>
                        {whyChooseData[0].para}
                    </p>
                }
            </div>
            {/* left 1 */}
            <div onClick={() => { setActiveCircle(1); scrollto() }}
                className={`${activeCircle == 1 && 'scale-[1.06] -translate-y-2 '}  
            cursor-pointer duration-300 hover:scale-[1.06] bg--300 
            xl:top-16 xl:left-2
            sm:top-24 sm:left-
            top-44 -left-3
                w-[9rem] h-[9rem] sm:w-[14rem] sm:h-[14rem] xl:w-[16rem] xl:h-[16rem] absolute `} >
                <img className=' absolute -bottom-4 ' src={require('../../Assest/lefttophexaa.webp')} alt="TopHexa" />
                <p className='absolute d-none d-sm-block top-28 left-20 xl:top-1/2 xl:left-28 text-white fw-semibold text-sm  ' >
                    {whyChooseData ? whyChooseData[1].short : "Who Can Learn"} </p>
                {!whyChooseData
                    && <p className='absolute w-4/5 text-sm top-3 sm:top-6 line-clamp-2 sm:px-3'>
                        Designed for beginners to professionals eager to enhance their skills and advance their careers
                    </p>}
                {whyChooseData
                    && <p className='absolute w-4/5 text-sm top-3 sm:top-6 line-clamp-2 sm:px-3'>
                        {whyChooseData[1].para} </p>}
            </div>
            {/* left 2 */}
            <div onClick={() => { setActiveCircle(2); scrollto() }}
                className={`${activeCircle == 2 && 'scale-[1.06] -translate-y-0 '}  
                cursor-pointer duration-300 hover:scale-[1.06]  
                xl:bottom-20 xl:left-8
            sm:bottom-28  sm:left-4 
            bottom-48
            w-[9rem] h-[9rem] sm:w-[14rem] sm:h-[14rem] xl:w-[16rem] absolute  xl:h-[16rem] `}>
                <img className=' absolute ' src={require('../../Assest/leftbottomhexa.webp')} alt="TopHexa" />
                <p className='absolute d-none d-sm-block top-16 left-20
                 xl:top-12 xl:left-24 text-white fw-semibold text-sm '>
                    {whyChooseData ? `${whyChooseData[2].short}` : <span> Training <br /> Structure </span>}    </p>
                <p className='absolute text-sm -bottom-4 sm:bottom-3 xl:bottom-5 w-4/5 line-clamp-2 sm:px-3' >
                    {whyChooseData ? whyChooseData[2].para : "Comprehensive curriculum covering core concepts tailored for real-world applications. "} </p>
            </div>
            {/* Right 1 */}
            <div onClick={() => { setActiveCircle(3); scrollto() }}
                className={`${activeCircle == 3 && 'scale-[1.06] -translate-y-2 '} 
             cursor-pointer duration-300 hover:scale-[1.06] 
              xl:top-32 xl:right-4
            sm:top-40 sm:right-3
            -right-2 top-56
             w-[9rem] h-[9rem] sm:w-[14rem] sm:h-[14rem]
              xl:w-[17rem] absolute  xl:h-[17rem] `} >
                <img className=' absolute ' src={require('../../Assest/righttophexa.webp')} alt="TopHexa" />
                <p className='absolute d-none d-sm-block top-10 left-14 xl:top-12 xl:left-20 text-white fw-semibold text-sm ' >
                    {whyChooseData ? `${whyChooseData[3].short}` : "Opportunities"} </p>
                {!whyChooseData && <p className='absolute text-sm w-4/5 -right-3 sm:right-0 -top-11 sm:-top-12 line-clamp-2 px-1 sm:px-3' >
                    {data.Careers[0] && data.Careers[0].Para ? data.Careers[0].Para : data.Why[0] && data.Why[0].Para
                    }
                </p>}
                {whyChooseData && <p className='absolute text-sm w-4/5 -right-3
                 sm:right-0 -top-11 sm:-top-12 line-clamp-2 px-1 sm:px-3' >
                    {whyChooseData[3].para} </p>}
            </div>
            {/* Right 2 */}
            <div onClick={() => { setActiveCircle(4); scrollto() }} className={`${activeCircle == 4 && 'scale-[1.06] -translate-y-2 '} 
             cursor-pointer duration-300 hover:scale-[1.06] xl:bottom-24 xl:right-2 
            sm:bottom-28 sm:right-0
            bottom-52 translate-y-2 sm:translate-y-0 -right-4
             w-[9rem] h-[9rem] sm:w-[14rem] sm:h-[14rem]
              xl:w-[16rem] absolute  xl:h-[16rem] `} >
                <img className={` absolute ${activeCircle == 4 && 'drop-shadow-sm '} `} src={require('../../Assest/rightbottomhexaa.webp')} alt="TopHexa" />
                <p className='absolute d-none d-sm-block top-16 left-16 xl:top-16 xl:left-20 text-white fw-semibold text-sm '>
                    {whyChooseData ? whyChooseData[4].short : "Outcome"} </p>
                {!whyChooseData && <p className='absolute text-sm -bottom-7 sm:-bottom-2 xl:bottom-0 w-4/5 right-0 line-clamp-2 sm:px-3' >
                    {data?.OutCome2 ? data.OutCome2[0].point : ''} </p>}
                {whyChooseData &&
                    <p className='absolute text-sm -bottom-7 sm:-bottom-2 xl:bottom-0 w-4/5 right-0 line-clamp-2 sm:px-3' >
                        {whyChooseData[4].para} </p>}
            </div>
            {/* bottom */}
            <div onClick={() => { setActiveCircle(5); scrollto() }} className={`${activeCircle == 5 && 'scale-[1.06] -translate-y-2 '}  
            cursor-pointer duration-300 hover:scale-[1.06] bg--200
            xl:bottom-5 xl:right-1/2
            sm:bottom-14 right-1/2
            bottom-40
            translate-x-1/2  
            w-[7rem] h-[7rem] sm:w-[11rem] sm:h-[11rem] 
            xl:w-[13rem] absolute  xl:h-[13rem]`} >
                <img className='bottom-0 absolute ' src={require('../../Assest/bottomhexaa.webp')} alt="botomHexa" />
                <p className='absolute d-none d-sm-block top-14 left-10 xl:top-16 xl:left-14 
                 text-white fw-semibold text-sm'>
                    {whyChooseData ? whyChooseData[5].short : "Various Packages"}
                </p>
                {!whyChooseData && <p className='absolute text-sm -bottom-14 sm:-bottom-16 line-clamp-2 sm:px-3' >Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Nisi, et. ipsum dolor sit amet consectetur </p>}
                {whyChooseData && <p className='absolute text-sm -bottom-14 sm:-bottom-16 line-clamp-2 sm:px-3' >
                    {whyChooseData[5].para} </p>}
            </div>
            {/* Shape 1 */}
        </div >
    )
}

export default CropCircle2