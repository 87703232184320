import React, { useEffect, useState } from 'react'
import HeadingTag from '../MiniCom/HeadingTag'
import PointsIcon from '../../Icons/PointsIcon'

const HowItWorks = ({ content }) => {
    let who = [
        'College students seeking real-world experience',
        'Freshers and job seekers wanting an edge',
        'Professionals seeking to switch careers or industries',
        'People restarting after a career break',
        'Anyone committed to a future in tech'
    ]
    let [selectedId, setSelectedId] = useState()
    let [howItWorks, setHowItWorks] = useState([
        {
            title: 'Submit Your Application',
            content: `Fill out the application form to express your interest, 
            and we’ll guide you through the simple steps to kickstart your career with training.`
        },
        {
            title: 'Commencement of Training',
            content: `Training kicks off on the specified start date. Prepare to dive into hands-on projects with real-world applications, guided by industry professionals and mentors.`
        },
        {
            title: 'Get Career Guidance',
            content: `Our expert team helps you navigate your options. We'll assist in selecting the right training path to align with your career goals and interests.`
        },
        {
            title: 'Start Working With Our Clients',
            content: `Apply what you learn by working with our client companies during the placement phase. Gain real work experience and build your portfolio.`
        },
        {
            title: 'Complete Admission Process',
            content: `Complete your registration by submitting the required documents. We'll ensure a smooth process so you can start training with everything in place.`
        },
        {
            title: 'Achieve Successful Completion',
            content: `Complete your training successfully by demonstrating your skills in real-world projects. Upon finishing, receive multiple certificates to validate your expertise.`
        },
        {
            title: 'Secure Payment & Documentation',
            content: `Finalize your enrollment by making payment and submitting documents. Once processed, you’ll receive confirmation and further details regarding commencement of the training .`
        },
        {
            title: 'Get Rewarded with a Job',
            content: `Earn a well-deserved job offer after successfully completing the program. With real-world skills and industry experience, you're ready to take on your new role confidently.`
        },
    ])
    useEffect(() => {
        if (content)
            setHowItWorks(content)
    }, [content])
    return (
        <div>
            {/* Section */}
            <main className=' py-4 bg-white ' >
                <section className='col-12 px-2 col-sm-11 mx-auto row  ' >
                    <article className='col-lg-6 ' >
                        <img src={require('../../Assest/TCRwho.png')} alt="TCRWHO"
                            className=' ' loading='lazy' />
                    </article>
                    <article className=' col-lg-6 flex ' >
                        <div className=' my-auto poppins p-3 ' >
                            <h5 className=' text-4xl fw-semibold ' >
                                <HeadingTag clr='4169FF' txt='Who Is This Program For?' />
                            </h5>
                            {
                                who.map((val) => (
                                    <div className='flex my-3 items-center gap-3 ' >
                                        <PointsIcon size={38} />
                                        <p className=' mb-0 ' >
                                            {val}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </article>
                </section>
            </main>
            {/* HOw it works */}
            <main className=' bg-[#EDEDED] py-4 ' >
                <h3 className='fw-semibold text-center my-3 w-fit mx-auto text-3xl ' >
                    <HeadingTag txt='How It Works' clr='4169FF' />
                </h3>
                <section className='col-12 px-2 col-sm-11 row mx-auto ' >
                    {
                        howItWorks.map((obj, index) => (
                            <div className=' col-lg-6 my-3 poppins' >
                                <div onMouseEnter={() => setSelectedId(index)}
                                    onMouseLeave={() => setSelectedId(-1)}
                                    className={`rounded border-r-[0.6rem] h-full cursor-default bg-white duration-500
                                     ${selectedId == index ? 'border-[#4169FF]' : "border-[#D9D9D9]"} hover:scale-[1.02] p-3 `} >
                                    <p className='fw-medium mb-2 ' > {obj.title} </p>
                                    <p className='text-sm text-slate-700 ' > {obj.content} </p>
                                </div>
                            </div>
                        ))
                    }
                    {/* {!content && <article className=' col-lg-6 my-3 rounded-xl border-[#001666] border-3 px-0 mx-auto ' >
                        <div className='w-full border-x-[0.9rem] border-[#FFFFFF] p-3 rounded-xl bg-[#001666] text-center text-slate-50 ' >
                            <h6 className=' text-2xl fw-semibold ' > Commencement of Training  </h6>
                            <p className=' w-[90%] mt-3 text-sm mx-auto ' >
                                Training kicks off on the specified start date. Prepare to dive into
                                hands-on projects with real-world applications, guided by industry professionals and mentors.
                            </p>

                        </div>
                    </article>} */}
                    <div className='flex ' >
                        <button className='mx-auto bg-blue-500 text-slate-50 poppins p-3 text-lg px-5 mt-4 rounded fw-medium ' onClick={() => {
                            document.getElementById('enquiryForm').scrollIntoView({ behavior: 'smooth', block: 'center' })
                        }} >
                            Enquire Now & Secure Your Spot!
                        </button>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default HowItWorks
