import axios from 'axios'
import React, { useContext, useState } from 'react'
import { hostname } from '../../App'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import { SlaData } from '../../Context/SLAstore'
import { useNavigate } from 'react-router-dom'

const GetInTouch = ({ css, page, options }) => {
    let { allCourses } = useContext(SlaData)
    const [contactForm, setContactForm] = useState({
        email: '',
        message: '',
        first_name: '',
        last_name: '',
        phone: '',
        captcha: false
    })
    let [loading, setloading] = useState('')
    let handlechange = (e) => {
        let { name, value } = e.target
        if (name == 'phone' && value.length > 10)
            value = contactForm.phone
        setContactForm((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [errorMessage, setErrormessage] = useState('')
    let validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(contactForm.email) && contactForm.phone != '' && contactForm.first_name != '' && contactForm.message != '') {
            setErrormessage('')
            return true
        }
        else if (!emailRegex.test(contactForm.email) && contactForm.phone == '' && contactForm.first_name == '' && contactForm.message == '') {
            setErrormessage('*Enter all the required inputs')
        }
        else if (!emailRegex.test(contactForm.email)) {
            setErrormessage("Enter the proper mail and other inputs")
        }
        else {
            setErrormessage('*Enter all the required inputs')
        }
    }
    let navigate = useNavigate()
    let postContact = () => {

        if (contactForm.captcha) {
            if (validateForm()) {
                setloading('contact')
                axios.post(`${hostname}/blog/ContactsInformation/`, contactForm).then((response) => {
                    console.log(response.data);
                    toast.success('Your Message has been sended, Our team will get touch in short time.')
                    setloading('')
                    navigate('/thank-you')
                    setContactForm({
                        email: '',
                        message: '',
                        first_name: '',
                        last_name: '',
                        phone: ''
                    })
                }).catch((error) => {
                    console.log(error);
                    setloading('')
                })
            }
        }
        else {
            setErrormessage('*Check the Captcha')

        }
    }
    return (
        <div className='text-slate-50 ' id='enquiryForm'>
            <main className={`w-full border-[0.5px] my-4 ${page ? ' text-slate-100 bg-slate-50/10 backdrop-blur ' : ''} border-slate-100 rounded-xl bg-slate-50/10 p-3  `} >
                <h4 className=' text-center text-2xl tracking-wide fw-semibold my-3 ' >Secure Your Spot Today! </h4>
                <div className=' poppins '>
                    <div className=' mb-3 row'>
                        {/* Name */}
                        <div className={` ${page ? '' : 'col-lg-6'}  my-1  flex items-center `} >
                            <label htmlFor="" className=' fw-semibold w-[120px]  ' > Name </label>
                            <input type="text" value={contactForm.first_name} name='first_name' onChange={handlechange} placeholder=''
                                className={`outline-none bg-slate-50/10  w-full p-2 rounded border-[0.5px]`} />
                        </div>
                        <div className={` ${page ? '' : 'col-lg-6'}  my-1  flex items-center `} >
                            <label htmlFor="" className=' fw-semibold w-[120px]  ' > Email </label>
                            <input type="email" value={contactForm.email} name='email' onChange={handlechange} placeholder=''
                                className={`outline-none bg-slate-50/10  w-full p-2 rounded border-[0.5px]`} />
                        </div>
                        <div className={` ${page ? '' : 'col-lg-6'}  my-1  flex items-center `} >
                            <label htmlFor="" className=' fw-semibold w-[120px]  ' > Contact </label>
                            <input type="number" value={contactForm.phone} name='phone' onChange={handlechange} placeholder=''
                                className={`outline-none bg-slate-50/10  w-full p-2 rounded border-[0.5px]`} />
                        </div>
                        <div className={`${page ? '' : 'col-lg-6'}  my-1  flex items-center  `} >
                            <label htmlFor="" className=' fw-semibold w-[120px]  ' > Program </label>
                            <select value={contactForm.last_name} name='last_name' onChange={handlechange}
                                className={`outline-none bg-slate-50/10  w-full p-2 rounded border-[0.5px]`} >
                                <option value="" className=' text-slate-800 ' >Select</option>
                                {
                                    options?.map((obj) => (
                                        <option value={obj.value} className=' text-slate-800 ' >{obj.label} </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className={` my-2   flex items-center `} >
                            <label htmlFor="" className={` fw-semibold ${page ? ' w-[120px] ' : "w-[100px]"}  `} > Query </label>
                            <textarea value={contactForm.message} name='message' onChange={handlechange} placeholder=''
                                className='outline-none bg-slate-50/10 w-full p-2 rounded border-[0.5px]' />
                        </div>

                    </div>
                    <div className='p-2 flex items-center justify-center'>
                        <ReCAPTCHA
                            className=' md:scale-[0.90] inputbg '
                            spellCheck={contactForm.captcha}
                            sitekey="6Ledg_QpAAAAAKgVH6WcItGafyMHUtoShRHFmMVc"
                            onChange={() => {
                                setContactForm((prev) => ({
                                    ...prev,
                                    captcha: !contactForm.captcha
                                }))
                            }}
                        />
                    </div>
                    <p className='text-red-600 text-center'> {errorMessage} </p>
                    <button disabled={loading == 'contact'} onClick={postContact}
                        className='border-[0.5px] z-100 rounded p-2 px-3 flex mx-auto bg-slate-50/10 my-1 text-white'>
                        {loading == 'contact' ? 'loading' : "Enroll Now "}</button>
                </div>
            </main>
        </div>
    )
}

export default GetInTouch