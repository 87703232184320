import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { domain, hostname } from '../App'
export const SlaData = createContext()
const SLAstore = (props) => {
    let [allCourses, setAllCourses] = useState()
    let a = 'hellow'
    let [allblog, setAllBlog] = useState()
    let getBlog = () => {
        axios.get(`${hostname}/blog/BlogViewSet/`).then((response) => {
            setAllBlog(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    let getAllCourses = () => {
        axios.get(`${hostname}/blog/Courses/`).then((response) => {
            setAllCourses(response.data)
            console.log(response.data,'Courses');
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getBlog()
        getAllCourses()
    }, [])
    function convertDate(dateStr) {
        // Create a new Date object from the date string
        const date = new Date(dateStr);

        // Define an array of month names
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        // Get the day of the month (1-31)
        const day = date.getDate();

        // Get the month name from the months array (0-11)
        const month = months[date.getMonth()];

        // Get the full year (YYYY)
        const year = date.getFullYear();

        // Return the formatted date string
        return `${month} ${day}, ${year}`;
    }
    let values = { convertDate, a, allCourses, getAllCourses, allblog, getBlog }
    return (
        <SlaData.Provider value={values} >
            {props.children}
        </SlaData.Provider>
    )
}

export default SLAstore