import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const WhatsappIcon = () => {
    let [show, setShow] = useState(true)
    let location = useLocation()
    // <!--Start of Tawk.to Script-->

    // <!--End of Tawk.to Script-->
    useEffect(() => {
        if (location && (location.pathname.includes('on-job-training') || location.pathname.includes('training-cum-recruitment'))) {
            setShow('enroll')
        }
        if (location && location.pathname.includes('thank-you'))
            setShow(false)
    }, [location])
    return (
        <div className={`position-fixed ${show && show != 'enroll' ? " bottom-3 " : 'right-4 bottom-[2%] lg:bottom-[10%] '} z-[30] `}>
            {show && show != 'enroll' && <div className='flex justify-between'>
                <a href="https://wa.me/+918904599283" target='_blank' className=''>
                    <img className='w-[70px] ms-4' src={require("../../Assest/whatsapp.png")}
                        alt="Whatsapp" />
                </a>
            </div>}
            {
                show && show == 'enroll' && < div >
                    <button onClick={() => {
                        document.getElementById('enquiryForm').scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }} className=' bg-green-700 px-5 py-3 text-xl p-2 text-yellow-100 rounded-full heartbeat fw-semibold rounded-ee-none shadow ' >
                        Enquire Now & Secure Your Spot!
                    </button>
                </div>
            }
        </div >
    )
}

export default WhatsappIcon