import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Facebook2Icon from '../../Icons/Facebook2Icon'
import LinkedIn2Icon from '../../Icons/LinkedIn2Icon'
import TwitterIcon2 from '../../Icons/TwitterIcon2'
import RightArrow from '../../Icons/RightArrow'

const NewFooter = () => {
    let navigate = useNavigate()
    return (
        <div className='poppins text-slate-50 py-4 bg-black relative ' >
            <img src={require('../../Assest/patternBG.png')} alt="BackgroundPattern"
                className=' h-full w-full absolute top-0 object-cover ' />
            <main className='relative col-12 col-sm-11 row mx-auto px-2 ' >
                <section className=' col-sm-6 mx-auto col-lg-3 ' >
                    <img onClick={() => navigate('/')} src={require('../../Assest/slaWhiteLogo.png')} alt='Logo'
                        className=' w-[80%] ' />
                </section>
                {/* <section className=' col-6 col-sm-3 col-lg-2  p-1 ' >
                    <p className=' fw-medium text-lg ' > Use Cases </p>
                    <Link to={'/blogs'} className=' text-sm text-decoration-none block my-2 text-slate-300 ' > Blog </Link>
                    <Link to={'/events'} className=' text-sm text-decoration-none block my-2 text-slate-300 ' >Events </Link>
                    <Link to={'/corporate-training'} className=' text-sm text-decoration-none block my-2 text-slate-300 ' > Corporate Training </Link>
                </section>
                <section className=' col-6 col-sm-3 col-lg-2  p-1 ' >

                    <p className='text-lg fw-semibold ' > Our Ventures </p>
                    <Link to={'https://www.meridatechminds.com'} target='_blank' className=' text-sm text-decoration-none block my-2 text-slate-300 ' > Merida Tech Minds </Link>
                    <Link to={'https://fortunetradingacademy.com/'} target='_blank' className=' text-sm text-decoration-none block my-2 text-slate-300 ' > Fortune Trading Academy </Link>
                    <Link to={'https://www.meridahr.com'} target='_blank' className=' text-sm text-decoration-none block my-2 text-slate-300 ' >Merida Hr Consultance </Link>
                    <Link to={'https://crm.skilllearningacademy.com'} target='_blank' className=' text-sm text-decoration-none block my-2 text-slate-300 ' > Customer Relationship Management </Link>
                    <Link to={'https://hrm.meridahr.com'} target='_blank' className=' text-sm text-decoration-none block my-2 text-slate-300 ' > Human Resource Management </Link>
                </section>
                <section className=' col-6 col-sm-5 col-lg-2  p-1 ' >

                    <p className=' fw-medium text-lg ' >Company </p>
                    <Link to={'/contact-us'} className=' text-sm text-decoration-none block my-2 text-slate-300 ' > Contact us </Link>
                    <Link to={'/why-skilllearningacademy'} className=' text-sm text-decoration-none block my-2 text-slate-300 ' >Why SLA </Link>

                </section>

                <section className=' col-sm-7 col-lg-3 bg-slate-50/10 p-3 poppins' >
                    <p> Get In Touch </p>
                    <div className='bg-white flex rounded-lg overflow-hidden my-3 ' >
                        <input type="text" className='p-2 w-full outline-none text-slate-700 ' placeholder='Email Address' />
                        <button className=' bg-[#0081FE] px-2 ' >
                            <RightArrow />
                        </button>

                    </div>
                    <p className='text-xs tracking-wide text-slate-300 ' >
                        Hello, we are Lift Media. Our goal is to translate
                        the positive effects from revolutionizing how companies engage with their clients & their team.
                    </p>
                </section>*/}


                <hr className='my-3 opacity-80  border-slate-100 ' />
                <section className='col-lg-4 my-2 ' ></section>
                <section className='col-lg-4 my-2 flex justify-around ' >
                    <button className=' ' onClick={() => navigate('/terms-and-condition')} >
                        Terms
                    </button>
                    <button className=' ' onClick={() => navigate('/disclaimer')} >
                        Disclaimer
                    </button>
                    {/* <button className=' ' onClick={() => navigate('/terms-and-condition')} >
                        Terms
                    </button> */}
                </section>
                <section className='col-lg-4 my-2 flex justify-around  ' >
                    <div className=' w-fit flex gap-4 ' >
                        <a href="https://www.facebook.com/MeridaSkillLearningAcad?mibextid=ZbWKwL" className='text-slate-300' target='_blank' >
                            <Facebook2Icon />
                        </a>
                        <a href="https://www.linkedin.com/company/merida-skill-learning-academy/?viewAsMember=true" target='_blank' className='text-slate-300'>
                            <LinkedIn2Icon />
                        </a>
                        <a href="https://x.com/MeridaSla" className='text-slate-300' target='_blank'>
                            <TwitterIcon2 />
                        </a>
                    </div>
                </section>

            </main>

        </div>
    )
}

export default NewFooter