import React from 'react'
import HeadingTag from '../MiniCom/HeadingTag'
import Slider from 'react-slick';

const DesignationWeAreHiring = ({ data }) => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        speed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className=' py-4 bg-white' >
            <h4 className='text-center fw-semibold mt-3 w-fit mx-auto poppins text-3xl ' >
                <HeadingTag txt='Designations We Are Hiring For' clr='4169FF' />
            </h4>
            <p className='w-[90%] fw-medium my-3 sm:w-[70%] lg:w-[50%] xl:w-[45%] mx-auto text-center ' >
                We’re looking for passionate individuals eager to train, grow, and excel in high-demand industries with real-world exposure.
            </p>
            <main className='container-fluid overflow-hidden ' >
                <Slider {...settings} className='m-0 py-4 ' >
                    {data?.map((obj, index) => (
                        <div key={index} className='slide-item p-3  ' >
                            <img src={obj.img ? obj.img : require('../../Assest/uiUxDesign.png')} alt="UiUX"
                                className=' rounded ' />
                            <h5 className='text-lg my-3 mb-1 poppins fw-medium   ' >{obj.title}</h5>
                            <p className=' my-2 ' >
                                {obj.content}
                            </p>
                        </div>
                    ))
                    }
                </Slider>
            </main>
        </div>
    )
}

export default DesignationWeAreHiring