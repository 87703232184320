import React from 'react'
import GetInTouch from './GetInTouch'
import { useNavigate } from 'react-router-dom'

const BannerTCR = ({ options }) => {
    let navigate = useNavigate()
    return (
        <div>
            <div className=' relative min-h-[100vh] ' >
                <img src={require('../../Assest/tcrBanner.png')} alt="Banner Bg"
                    className='absolute top-0 w-full object-cover h-full z-0 ' />
                <main className=' row col-12 px-2 col-sm-11 mx-auto relative z-10 '>
                    <nav className=' col-12  w-full absolute top-0 z-[30] ' >
                        <div className='flex items-center justify-between   ' >

                            <img onClick={() => navigate('/')} src={require('../../Assest/slaWhiteLogo.png')} alt="White logo"
                                className=' w-[120px] ' />
                            {/* <button onClick={()=>navigate('/contact-us')} className='bg-[#2442B0] p-2 px-3 rounded shadow text-slate-50 ' >
                                Contact Us
                            </button> */}
                        </div>
                    </nav>
                    <section className=' order-2 flex flex-col  order-lg-1 min-h-[100vh]  col-lg-7 relative' >
                        <p className='h-[10vh]  w-full  ' ></p>
                        <div className='w-full text-slate-50 poppins ' >
                            <p className=' tracking-wider text-xl my-2' > Attention Freshers & Job Seekers </p>
                            <h3 className=' nohemi text-4xl sm:text-5xl tracking-wide ' > Earn While You Train with Training & Recruitment Program </h3>
                            <p className=' my-3 text-sm ' >
                                You heard right! Our Training and Recruitment Program lets you learn and work with our client companies from day one. Our clients need skilled workers, so we built a course that teaches important skills in software and business while placing you in real job roles as you study. Gain work experience, pick up useful skills, and earn up to ₹5 LPA as you train.
                                Secure your spot now to get trained by industry experts, work on real projects, and earn as you grow!
                            </p>
                            <GetInTouch options={options} />
                        </div>
                    </section>
                    <section className='flex order-1 order-lg-2 min-h-[100vh] d-none d-lg-flex  col-lg-5 relative' >
                        <img src={require('../../Assest/tcrBannerPic.png')} alt="Banner Image"
                            className=' max-h-[90vh]  ms-auto flex mt-auto ' />
                    </section>
                </main>
            </div>

        </div>
    )
}

export default BannerTCR