import React from 'react'
import StarIcon from '../../Icons/StarIcon'

const RatingCom = ({ num }) => {
    return (
        <div className=' flex gap-3 items-center text-yellow-500 ' >
           
            {
                new Array(num ? num : 3).map(() => (
                    <StarIcon />
                ))
            }
        </div>
    )
}

export default RatingCom