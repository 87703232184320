import React from "react";

const PointsIcon = ({ size = 38 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={size}
                height={size}
            >
                <rect width={size} height={size} fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0)">
                <path
                    d="M18.9998 34.8337C16.8096 34.8337 14.7512 34.418 12.8248 33.5868C10.8984 32.7555 9.22275 31.6274 7.79775 30.2024C6.37275 28.7774 5.24463 27.1017 4.41338 25.1753C3.58213 23.2489 3.1665 21.1906 3.1665 19.0003C3.1665 16.81 3.58213 14.7517 4.41338 12.8253C5.24463 10.8989 6.37275 9.22324 7.79775 7.79824C9.22275 6.37324 10.8984 5.24512 12.8248 4.41387C14.7512 3.58262 16.8096 3.16699 18.9998 3.16699C20.7151 3.16699 22.338 3.41769 23.8686 3.91908C25.3991 4.42046 26.8109 5.11977 28.104 6.01699L25.8082 8.35241C24.8054 7.71908 23.7366 7.22428 22.6019 6.86803C21.4672 6.51178 20.2665 6.33366 18.9998 6.33366C15.4901 6.33366 12.5016 7.56734 10.0342 10.0347C7.56685 12.5021 6.33317 15.4906 6.33317 19.0003C6.33317 22.51 7.56685 25.4986 10.0342 27.966C12.5016 30.4333 15.4901 31.667 18.9998 31.667C22.5096 31.667 25.4981 30.4333 27.9655 27.966C30.4328 25.4986 31.6665 22.51 31.6665 19.0003C31.6665 18.5253 31.6401 18.0503 31.5873 17.5753C31.5346 17.1003 31.4554 16.6385 31.3498 16.1899L33.9228 13.617C34.213 14.4614 34.4373 15.3323 34.5957 16.2295C34.754 17.1267 34.8332 18.0503 34.8332 19.0003C34.8332 21.1906 34.4175 23.2489 33.5863 25.1753C32.755 27.1017 31.6269 28.7774 30.2019 30.2024C28.7769 31.6274 27.1012 32.7555 25.1748 33.5868C23.2484 34.418 21.1901 34.8337 18.9998 34.8337ZM16.7832 26.2837L10.054 19.5545L12.2707 17.3378L16.7832 21.8503L32.6165 5.97741L34.8332 8.19408L16.7832 26.2837Z"
                    fill="#4169FF"
                />
            </g>
        </svg>
    );
};

export default PointsIcon;
