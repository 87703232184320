import React from 'react'

const Facebook2Icon = ({ size }) => {
    return (
        <svg width={size ? size : "37"} height={size ? size : "37"} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="white" stroke-width="1.5" />
            <path d="M20.0932 14.0208H21.7016C21.8495 14.0208 21.9696 13.9076 21.9696 13.7682V12.2526C21.9696 12.1132 21.8495 12 21.7016 12H20.0932C18.4677 12 17.1445 13.2463 17.1445 14.7786V16.5468H15.2681C15.1201 16.5468 15 16.6599 15 16.7994V18.3149C15 18.4544 15.1201 18.5675 15.2681 18.5675H17.1445V23.8721C17.1445 24.0115 17.2646 24.1247 17.4126 24.1247H19.0209C19.1689 24.1247 19.289 24.0115 19.289 23.8721V18.5675H21.1655C21.2807 18.5675 21.3831 18.4978 21.4201 18.3948L21.9562 16.8792C21.9836 16.8024 21.9696 16.7175 21.9192 16.6513C21.8683 16.5857 21.7879 16.5468 21.7016 16.5468H19.289V14.7786C19.289 14.3608 19.6498 14.0208 20.0932 14.0208Z" fill="white" />
        </svg>

    )
}

export default Facebook2Icon