import React, { useEffect, useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'
import NavBar from '../Component/NavBar'
import Footer from '../Component/Footer'
import NewFooter from '../Component/TCR/NewFooter'
import HeadingTag from '../Component/MiniCom/HeadingTag'
import TestimonialTCR from '../Component/TCR/TestimonialTCR'
import TrainingJuorney from '../Component/TCR/TrainingJuorney'
import HowItWorks from '../Component/TCR/HowItWorks'
import ExclusiveFeature from '../Component/TCR/ExclusiveFeature'
import DesignationWeAreHiring from '../Component/TCR/DesignationWeAreHiring'
import BannerTCR from '../Component/TCR/BannerTCR'
import { useNavigate } from 'react-router-dom'
import GetInTouch from '../Component/TCR/GetInTouch'

const OnJobTraningPage = () => {
  let navigate = useNavigate()
  let features = [
    {
      css: 'col-sm-6',
      title: 'Start Training with an Offer Letter',
      content: `No more waiting or job hunting! Get your offer letter on day one and start learning while already being part of the company.`,
      img: '../../Assest/startTraining.png',
      bgclr: 'bg-[#FFD8D8]'
    },
    {
      css: 'col-sm-6',
      title: 'Project-Based Learning',
      content: `Earn real experience instead of just learning theories in a classroom. Work on projects that teach you exactly how things work, so you’ll feel confident and ready for any job.`,
      img: '../../Assest/projectBased.png',
      bgclr: 'bg-[#FFEFD8]'
    },
    {
      css: 'col-sm-6',
      title: 'Work-Integrated Training',
      content: `Get practical experience from the start. With work integrated into your training, you’ll apply your lessons to real tasks, ensuring you’re job-ready by the end.`,
      img: '../../Assest/workIntegrated.png',
      bgclr: 'bg-[#EBFFD8]'
    },
    {
      css: 'col-sm-6',
      title: 'Industry-Specific Tools & Techniques',
      content: `No outdated methods! Train with the latest tools and techniques used by top companies, so you know exactly what’s needed in today’s job market.`,
      img: '../../Assest/industry.png',
      bgclr: 'bg-[#D8FFDC]'
    },
    {
      css: 'col-sm-6',
      title: 'Multiple Certificates Upon Project Completion',
      content: `Showcase your hard work with certificates earned after completing real-world projects, boosting your resume and proving you’ve got the experience employers are looking for.`,
      img: '../../Assest/multiplecertificate.png',
      bgclr: 'bg-[#D8DEFF]'
    },
    {
      css: 'col-sm-6',
      title: 'Exposure to Prompt Engineering & AI Tools',
      content: `Learn how to use the latest tech like AI tools and prompt engineering to stay ahead of the curve and add cutting-edge skills to your resume.`,
      img: '../../Assest/promtEngineering.png',
      bgclr: 'bg-[#D8DEFF]'
    },
    {
      css: 'col-sm-6',
      title: 'Networking with Experts',
      content: `Meet professionals who’ve been where you are! Connect with industry experts, mentors, and peers who can guide, support, and help you grow in your career.`,
      img: '../../Assest/networking.png',
      bgclr: 'bg-[#EBD8FF]'
    },
    {
      css: 'col-sm-6',
      title: 'Mentorship from Industry Experts',
      content: `Learn directly from industry experts who guide you at every step. Get real-world insights, hands-on training, and the support you need to land a great job!`,
      img: '../Assest/startTraining.png',
      bgclr: 'bg-red-100'
    },
  ]
  let howItWorks = [
    {
      title: 'Submit Your Application',
      content: `Fill out the application form to express your interest, 
      and we’ll guide you through the simple steps to kickstart your career with training.`
    },
    {
      title: 'Start Working With Our Clients',
      content: `Apply what you learn by working with our client companies during the placement phase. Gain real work experience and build your portfolio.`
    },
    {
      title: 'Get Career Guidance',
      content: `Our expert team helps you navigate your options. We'll assist in selecting the right training path to align with your career goals and interests.`
    },
    {
      title: 'Achieve Successful Completion ',
      content: `Complete your training successfully by demonstrating your skills in real-world projects. Upon finishing, receive multiple certificates to validate your expertise.`
    },
    {
      title: 'Complete Admission Process',
      content: `Complete your registration by submitting the required documents. We'll ensure a smooth process so you can start training with everything in place.`
    },
    {
      title: 'Get Rewarded with a Job ',
      content: `Earn a well-deserved job offer after successfully completing the program. With real-world skills and industry experience, you're ready to take on your new role confidently.`
    },
    {
      title: 'Secure Payment & Documentation',
      content: `Finalize your enrollment by making payment and submitting documents. Once processed, you’ll receive confirmation and further details regarding commencement of the training .`
    },
    {
      title: 'Training Start Date',
      content: `Training kicks off on the specified start date. Prepare to dive into hands-on projects with real-world applications, guided by industry professionals and mentors.`
    },
  ]
  let journeyData = [
    {
      img: '../../Assest/identifySet-min.png',
      title: 'Identifying Your Skill Set',
      content: `Our expert counselors assess your skills & help you choose the right technology to focus on`
    },
    {
      img: '../../Assest/buildstrongfoundation-min.png',
      title: 'Build a Strong Foundation',
      points: [
        'Learn from an industry-endorsed syllabus designed by experts',
        'Get a perfect balance of theory & practical learning'
      ]
    },
    {
      img: '../../Assest/gainhands-min.png',
      title: 'Gain Hands-On Work Experience',
      points: [
        'Work on live projects with industry-specific tools',
        'Apply skills in real scenarios under expert guidance',
        'Get practical exposure that makes you job-ready'
      ]
    },
    {
      img: '../../Assest/strongPortfolio-min.png',
      title: 'Create a Strong Portfolio',
      points: [
        'Showcase your best work from real-world projects',
        'Build an ATS-friendly resume',
        'Get technical interview training with expert feedback',
        'Improve communication & soft skills for workplace success'
      ]
    },
    {
      img: '../Assest/demaninDevelop.png',
      title: 'Develop In-Demand Skills',
      points: [
        `Get trained on industry-relevant tools & technologies`,
        'Learn workplace communication & collaboration best practices',
        'Develop problem-solving & analytical thinking skills',
        'Work on case studies & simulations to tackle real-world challenges.'
      ]
    },
    {
      img: '../../Assest/transitionstrongrole-min.png',
      title: 'Transition to a Permanent Role',
      points: [
        'Exclusive job opportunities with top recruiters',
        'Placement support for direct company connections',
        `Mock interviews to sharpen your confidence`,
        `Career mentorship for long-term success`,
        `Secure a Full-time role & Earn Big`
      ]
    },
  ]
  let designation = [
    {
      img: '../Assest/uiUxDesign.png',
      title: 'UI/UX & Graphic Design',
      content: ` Develop design skills to create appealing visuals and user-friendly 
      interfaces that capture attention and engage users.`
    },
    {
      img: '../Assest/digitalMarketingCard.png',
      title: 'Digital Marketing',
      content: `Learn effective online marketing techniques, including SEO, social media, and ads, to drive growth and reach audiences.`
    },
    {
      img: '../Assest/appDevelopment.png',
      title: 'Mobile App Development',
      content: ` Develop design skills to create appealing visuals and user-friendly interfaces that capture attention and engage users.`
    },
    {
      img: '../Assest/devopsCard.png',
      title: 'Full Stack Web Development',
      content: `Gain skills in your chosen field: Python, Java,  or MERN and build fully functional web applications,
       from front-end interfaces to back-end systems.`
    },
    {
      img: '../Assest/dataScience.png',
      title: 'HR & Talent Management',
      content: `Get real experience in recruiting, team management, and workplace culture, preparing you for essential HR roles.`
    },
  ]
  useEffect(() => {
    // window.scrollTo(0, 0)
  }, [])
  return (
    <div className='min-h-[50vh] bg-white' >
      <> <Helmet>
        <title>Latest Tech News & Insights - Read Our Blog | TechTalk </title>
        <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
        <link rel="canonical" href={`${domain}/on-job-traing`} />
      </Helmet>
        <h1 className='d-none'>informative blog content </h1>
        <h2 className='d-none'>best blogging tips and tricks </h2>
      </>
      {/* Banner */}
      <main className=' min-h-[90vh] relative ' >
        <img src={require('../Assest/ojtBanner.png')} alt="OJT banner"
          className='absolute w-full h-full object-cover ' />
        <article className=' relative w-full ' >

          <section className=' col-12 px-2 row  justify-between col-sm-11 mx-auto  ' >

            <div className='flex items-center justify-between  py-2 ' >
              <img onClick={() => navigate('/')} src={require('../Assest/slaWhiteLogo.png')} alt="White logo"
                className=' w-[120px] ' />
              {/* <button onClick={() => navigate('/contact-us')} className='bg-white fw-semibold p-2 px-3 rounded shadow text-blue-600 ' >
                Contact Us
              </button> */}
            </div>
            {/* Content */}
            <article className='col-lg-7 col-xl-6 my-3 min-h-[30vh] lg:min-h-[60vh] text-slate-100 flex ' >
              <div className=' ' >
                {/* <p className=' tracking-wider text-xl my-2' > Attention Freshers & Job Seekers </p> */}
                <h3 className=' nohemi text-4xl sm:text-[48px] leading-[110%] xl:text-[55px] fw-bold tracking-wider ' >
                  Start Your Training With An Offer Letter </h3>
                <p className='text-lg tracking-wide w-fit my-3 poppins ' >
                  Attention <span className='text-2xl fw-semibold ' > Freshers
                  </span> & <span className='text-[20px] lg:text-[25px] fw-semibold  ' > Job Seekers </span>
                </p>
                <p className=' my-3 text-[14px] ' >
                  You heard that right. Our company is offering an On-Job Training Program that makes you an employee from day one. With strong demand from our teams and clients, we have set up a training course for various roles in software and business.
                  Get real-time exposure, learn industry-relevant skills, and earn up to ₹5 LPA. Enroll now and get paid while you train!
                </p>
                {/* Last content */}
                <p className='h-[30px] d-none d-lg-block' ></p>

                <div className='my-4 flex items-center flex-wrap' >
                  <article className=' lg:absolute relative -left-6 sm:-left-14 lg:left-0 ' >
                    <div className=' relative xl:min-w-[15rem] min-w-[12rem]' >
                      <div className='border-y-2  text-end xl:min-w-[15rem] min-w-[12rem] text-[#2C62FC]
                   poppins text-xl fw-semibold z-10 text-nowrap bg-white rounded-e-xl relative p-3 border-e-2 ' >
                        Earn Up to ₹5 LPA
                      </div>
                      {/* Border */}
                      <div className='w-full xl:min-w-[15.3rem] min-w-[12.3rem] h-full absolute border-y-[1.5px] border-e-[1.5px] left-0 rounded-e-xl z-0 -top-[5.5px] ' >

                      </div>
                    </div>
                  </article>

                </div>
              </div>
            </article>
            <article className='col-lg-5 ' >
              <GetInTouch options={designation.map((obj) => ({ value: obj.title, label: obj.title }))} />
            </article>
          </section>
        </article>

      </main>
      {/* Designation we hire for  */}
      {/*  */}
      <main className='relative min-h-[48vh] xl:min-h-[70vh] 2xl:min-h-[70vh] container-fluid flex m-0 max-w-[100vw] ' >
        <article className=' right-3 col-lg-10 row p-3 shadow lg:rounded-s-xl ms-auto bg-white lg:-translate-y-[5%] xl:-translate-y-[10vh] ' >
          <section className='col-sm-6 col-lg-4 ' >
            <div className=' flex text-center h-full ' >
              <p className='w-fit m-auto capitalize text-slate-700 text-4xl px-2 fw-bold ' >
                <span className=' text-[#4169FF] ' > Desginations </span> <br /> We are <br /> Hiring for
              </p>
            </div>
          </section>
          {
            designation.map((obj) => (
              <section className='col-sm-6 col-lg-4 my-3 ' >
                <div className='bg-[#ECECEC] h-full p-3 rounded-xl  ' >
                  <img src={obj.img} alt="CardImage"
                    className=' rounded-xl w-full ' />
                  <p className=' my-2 text-lg fw-semibold ' > {obj.title} </p>
                  <p className=' text-sm ' >
                    {obj.content}
                  </p>
                </div>
              </section>
            ))
          }

        </article>
      </main>
      {/* <DesignationWeAreHiring data={designation} /> */}
      <ExclusiveFeature content={features} />
      <HowItWorks content={howItWorks} />
      <TrainingJuorney data={journeyData} />
      <TestimonialTCR page />
      <main className=' py-5  ' >
        <h5 className=' fw-semibold text-2xl lg:text-3xl w-fit mx-auto ' >
          <HeadingTag txt='About Us' clr='4169FF' />
        </h5>
        <section className='poppins px-2 col-12 col-sm-11 mx-auto text-center ' >
          <p className=' my-3 ' >
            At Merida Skill Learning Academy, we recognized a massive demand for practical, job-ready training that bridges the gap between classroom learning and real-world employment. We started our On Job Training Program because we believe that learning by doing is the best way to grow. Our mission is to empower aspiring professionals by integrating structured, project-based learning with live work scenarios, ensuring every participant becomes industry-ready from day one.
          </p>
          <p className=' my-3 ' >
            We are part of the Merida group, which also includes Merida Tech Minds, our creative branch that offers cutting-edge tech solutions and Merida HR Consulting which focuses on smart talent management. Together, our business verticals possess decades of expertise to provide career development opportunities, enabling our students to secure rewarding roles and thrive in their careers!
          </p>
        </section>
      </main>
      <NewFooter />
    </div>
  )
}

export default OnJobTraningPage