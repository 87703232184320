import React, { useContext, useEffect, useRef, useState } from 'react'
import PinkTick from '../../Icons/PinkTick'
import FacebookIcon from '../../Icons/FacebookIcon'
import InstagramIcon from '../../Icons/InstagramIcon'
import YoutubeIcon from '../../Icons/YoutubeIcon'
import TwitterIcon from '../../Icons/TwitterIcon'
import BannerCourseCard from '../Card/BannerCourseCard'
import defaultImage from '../../Assest/Hero.webp';
import { SlaData } from '../../Context/SLAstore'
import { useNavigate } from 'react-router-dom'
import StudentContact from '../StudentContact'
import NavBar from '../NavBar'
import SearchIcon from '../../Icons/SearchIcon'
import CourseEnquiryForm from '../CourseComponent/CourseEnquiryForm'
const CourseBanner3 = ({ page, grd, theme, image, content, enquiryboxcss, content_css, points, txtb, txta, spl, cssa, cssb, splcss }) => {
    let backgroundimg = {}
    let navigate = useNavigate()
    let formref = useRef()
    let [showForm, setShowForm] = useState()
    let { allCourses } = useContext(SlaData)
    let [searchWord, setSearchWord] = useState('')
    let [studentShow, setStudentShow] = useState(false)

    let [filterList, setfilterList] = useState()
    let search = () => {
        let filteredArry = [...allCourses].filter((obj) => obj.Title.toLowerCase().indexOf(searchWord.toLowerCase()) != -1)
        setfilterList(filteredArry)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formref.current && !formref.current.contains(event.target))
                setShowForm(false)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.addEventListener('mousedown', handleClickOutside)
        }
    }, [])
    return (
        <div className={` ${grd ? grd : '  bg-black'} relative `} >
            <NavBar theme={theme} />
            <button onClick={() => setShowForm((prev) => !prev)}
                className='btnbg2 
                       absolute left-0 z-10 top-1/2 d-lg-none
                        rounded-r -translate-y-1/2  
                        text-white p-2 text-xs vrl ' >
                Quick enquiry
            </button>
            <main onClick={() => setfilterList(false)} className={` xl:min-h-[89vh] relative flex  p-4 `} >
                <button onClick={() => setShowForm((prev) => !prev)}
                    className={`btnbg2 absolute top-4 z-10 d-none d-lg-block
                    rounded fw-medium px-4 text-white p-2 text-sm poppins 
                    ${enquiryboxcss ? enquiryboxcss : '  right-1/4 '}  `} >
                    Enquire Now
                </button>
                <div ref={formref} className={`${showForm ? 'opacity-100 ' : 'hidden  '} 
                        duration-400 w-[80%] max-w-[400px] absolute  z-20 
                       top-14 left-1/2 -translate-x-1/2 
                         lg:translate-x- lg:left-3/4 `} >
                    <CourseEnquiryForm />
                </div>
                {/* <section className='absolute  gap-3 justify-center  items-center top-50 
                -translate-y-1/2 right-1 flex flex-col  ' >
                    <button className='icon text-violet-500 mx-1 hover:text-blue-400 duration-200 ' >
                        <FacebookIcon />
                    </button>
                    <button className='icon text-violet-500 mx-1 hover:text-blue-400 duration-200 ' >
                        <InstagramIcon />
                    </button>
                    <button className='icon text-violet-500 mx-1 hover:text-blue-400 duration-200 ' >
                        <YoutubeIcon />
                    </button>
                    <button className='icon text-violet-500 mx-1 hover:text-blue-400 duration-200 ' >
                        <TwitterIcon />
                    </button>
                </section> */}

                <section

                    className=' m-auto row w-[98%] min-h-[70vh] xl:min-h-[80vh]
                  p-3 rounded-3xl ' >
                    <article className={` col-lg-7 flex flex-col justify-between
                         ${page != 'course' && "justify-between"} `}>
                        <>
                            <div>

                                <h4 className={` ${cssb ? cssb : "audiowide  text-5xl text-white"} xl:text-5xl
                                     md:w-4/5 lg:w-4/6 xl:w-[72%] h-fit `} >
                                    <span className={` ${cssb} `} >{txtb ? txtb : ""}  </span>
                                    <span className={` ${splcss ? splcss : "txtgrd"} `}> {spl ? spl : ""} </span>
                                    <span className={` ${cssa} `} > {txta ? txta : ""}  </span>
                                </h4>
                                <p className={`text-sm ${content_css ? content_css : "sulphur text-slate-300"} md:w-4/5  my-4 `} >
                                    {content ? content : ` Build stunning, high-performance apps for iOS, Android, and Web with Flutter. Achieve faster development,
                                    seamless scalability, and exceptional user experiences—all from a single codebase.`} </p>

                                <div className='my-4 flex relative ' >
                                    <input onKeyUp={(e) => {
                                        if (e.key == 'Enter')
                                            search()
                                    }} type="text" value={searchWord} onChange={(e) => setSearchWord(e.target.value)}
                                        placeholder='Search your course... '
                                        className='p-2 border-2  w-2/3 sm:w-1/2  rounded outline-none
                                     text-white border-slate-600
                                     bg-slate-800 bg-opacity-75 ' />
                                    <button onClick={() => search()} className={` btnbg2 text-xs sm:text-base px-3 p-2 bg-opacity-90 rounded mx-2 text-white`} >
                                        <SearchIcon />
                                    </button>
                                    {filterList &&
                                        <section className='rounded z-10 w-2/3 sm:w-1/2 overflow-y-scroll searchscroll h-32 absolute 
                                text-white border-slate-700 border-2
                                     bg-slate-800 top-14 ' >
                                            {filterList.length > 0 ? filterList.map((obj, index) => (
                                                <button
                                                    onClick={() => {
                                                        setSearchWord('');
                                                        navigate(`/${obj.slug}`)
                                                    }}
                                                    className='p-1 px-2 text-xs w-full text-start hover:bg-slate-700 ' > {obj.Title} </button>
                                            )) : <p className='p-2 text-sm '>No Result found </p>
                                            }
                                        </section>}
                                </div>
                            </div>

                            {page != 'course' && <p className='md:w-4/5 text-slate-300 poppins text-sm my-2 ' >
                                What we do to , build your profile
                                {/* <a className='text-slate-300  ' href=""> privacy policy. </a> */}
                            </p>}
                            {page != 'course' &&
                                <section className='flex gap-3 my-3' >
                                    <div className='flex gap-2 text-white items-center  ' >
                                        <span className='text-pink-200 ' >
                                            <PinkTick />
                                        </span>
                                        Multiple case studies
                                    </div>
                                    <div className='flex gap-2 text-white items-center ' >
                                        <span className='text-pink-200 ' >
                                            <PinkTick />
                                        </span>
                                        Multiple industry projects
                                    </div>
                                    <div className='flex d-none d-sm-flex gap-2 text-white items-center ' >
                                        <span className='text-pink-200 ' >
                                            <PinkTick />
                                        </span>
                                        Multiple certifications to build your profile
                                    </div>
                                </section>
                            }

                        </>
                        {page != 'course' &&
                            <section className='row mt-5 ' >
                                <div className=' p-2 col-6 col-md-3' >
                                    <div className='homebox p-2 h-full homeboxtext rounded-t-xl pb-4 ' >
                                        <p className=' fw-bold mb-0 '>Project Based
                                            <span className='block fw-normal '> Learning </span> </p>
                                    </div>
                                </div>
                                <div className=' p-2 col-6 col-md-3' >
                                    <div className='homebox p-2 h-full homeboxtext rounded-t-xl pb-4 ' >
                                        <p className=' fw-bold mb-0 '> Work Integrated
                                            <span className='block fw-normal '> Learning </span> </p>
                                    </div>
                                </div>
                                <div className=' p-2 col-6 col-md-3' >
                                    <div className='homebox p-2 h-full homeboxtext rounded-t-xl pb-4 ' >
                                        <p className=' fw-bold mb-0 '>Flexible Hybrid
                                            <span className='block fw-normal '> Learning </span> </p>
                                    </div>
                                </div>
                                <div className=' p-2 col-6 col-md-3' >
                                    <div className='homebox p-2 h-full homeboxtext rounded-t-xl pb-4 ' >
                                        <p className=' fw-bold mb-0 '>Guest lectures
                                            <span className='block fw-normal '>& workshops </span> </p>
                                    </div>
                                </div>
                            </section>
                        }
                        {
                            page == 'course' &&
                            <section>
                                <ul className={`${theme == 'dark' ? 'text-slate-800' : 'text-slate-100'} list-disc  almaz tracking-wider `} >
                                    {
                                        points ? points.map((obj) => (
                                            <li className=' my-2 text-sm ' >
                                                <BannerCourseCard heading={obj.Heading} content={obj.Content} />
                                            </li>
                                        )) :
                                            ''
                                    }
                                </ul>

                            </section>}
                        <div className='my-4 flex items-center gap-3 ' >
                            <button className='flex ' >
                                <img src={require('../../Assest/Googlereview.webp')}
                                    className='w-[7rem] ' alt="Google review" />
                            </button>
                            <button className='flex ' >
                                <img src={require('../../Assest/Googlereview.webp')}
                                    className='w-[7rem] ' alt="Google review" />
                            </button>
                            <button className='flex ' >
                                <img src={require('../../Assest/Googlereview.webp')}
                                    className='w-[7rem] ' alt="Google review" />
                            </button>
                        </div>
                        {/* {
                            page == 'course' &&
                            <section className='absolute -bottom-36 lg:-bottom-28 xl:-bottom-20 d-none d-sm-none ' >
                                <article className='flex justify-between gap-3 ' >
                                    <BannerCourseCard heading="Work Integration" content='Engage with industry projects and gain practical skills. 
                                    Our industry experts guide you to become job-ready from day one.' />
                                    <BannerCourseCard heading='Project-Based Learning' content='Sharpen your skills through hands-on tasks. This 
                                    practical approach sets you up for success in your chosen field.' />
                                    <BannerCourseCard heading='Networking Opportunities' content='Connect with like-minded peers and industry 
                                    professionals. Find job opportunities through our active and supportive community.' />
                                </article>

                            </section>
                        } */}

                    </article>
                    <article className='col-lg-5 flex relative  ' >

                        <img src={image ? image : require('../../Assest/bannerGif.gif')} alt="GifFile"
                            className=' object-cover w-full  ' />
                    </article>
                </section>
            </main>
            <StudentContact show={studentShow} setshow={setStudentShow} />
        </div>
    )
}

export default CourseBanner3