import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import RightSideBlog from '../Component/RightSideBlog'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'
import NavBar from '../Component/NavBar'
import Footer from '../Component/Footer'

const InplantTraining = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let who = [
        {
            heading: 'Recent Graduates',
            para: 'Start your career with the best Inplant Training that prepares you for future educational and professional opportunities.'
        },
        {
            heading: 'College Students ',
            para: 'Enhance your academic knowledge with practical experience and gain a competitive edge for internships and job placements.'
        },
        {
            heading: 'Aspiring Professionals ',
            para: `Develop your skills and gain industry-specific experience, making you more attractive to potential 
            employers.`
        },
        {
            heading: 'Career Changers ',
            para: 'Acquire hands-on experience in a new field, facilitating a smooth transition and enhancing your employability.'
        },
        {
            heading: 'Working Professionals  ',
            para: 'Expand your expertise with Inplant Training to stay current with industry trends and advance your career.'
        },
        {
            heading: 'Educators and Trainers  ',
            para: `Incorporate our Inplant Training program into your curriculum to provide students with real-world exposure and practical 
            skills.`
        },
        {
            heading: 'Tech Enthusiasts ',
            para: `Engage in specialized Inplant Training relevant to the tech industry, gaining practical skills that enhance your technical
             proficiency.`
        },

    ]
    let why = [
        {
            heading: 'Hands-On Experience',
            para: 'Dive into real-world projects that replicate industry environments, providing you with practical skills and a deep understanding of industry practices.'
        },
        {
            heading: 'Industry-Relevant Training ',
            para: 'Benefit from a program designed to meet current industry standards, helping you gain valuable skills that employers actively seek.'
        },
        {
            heading: 'Real-World Projects  ',
            para: `Engage in projects that reflect actual work environments, providing you with valuable experience and enhancing your resume.`
        },
        {
            heading: 'Flexible Learning  ',
            para: 'Take advantage of our hybrid approach, combining online learning with practical, in-person sessions for a training experience.'
        },
        {
            heading: 'Skill Development  ',
            para: 'Develop both technical and soft skills through practical applications and feedback, preparing you for various professional challenges.'
        },
        {
            heading: 'Career Preparation  ',
            para: `Ensure you are job-ready with structured Inplant Training that equips you with the skills and knowledge needed for your career advancement.`
        },

    ]
    return (
        <div>
            <NavBar theme='dark' />
            <>
                <Helmet>
                    <title>Showcase Your Real-Time Skill and Experience in Our Office.</title>
                    <meta name="description" content="We allow the students our office to gain practical insights, observe operations firsthand and interact with expert professionals provide learning opportunities and 
                experience. " />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <link rel="canonical" href={`${domain}/inplant-training`} />
                </Helmet>
                <h1 className='d-none'>student visit the in house office </h1>
                <h2 className='d-none'>real experience in office work </h2>
            </>
            <main className='row my-4 container mx-auto'>
                <section className='col-lg-8 col-xl-9 '>
                    <div className='text-lg '>
                        <img src={require('../Assest/inplantTraining.webp')} loading='lazy' className=' w-full rounded object-cover '
                            alt="Corporate training" />
                        <h3 className=' my-4 fw-semibold '> Inplant Training  </h3>
                        <p>Inplant Training is essential for students and professionals eager to gain hands-on experience in their chosen field. At SkillLearningAcademy, our Inplant Training Program offers valuable practical experience and industry exposure to boost your career prospects. We focus on blending theoretical knowledge with real-world application, preparing you for the challenges of the
                            workplace. </p>
                        <p>
                            We cover elements such as the benefits of Inplant Training and strategies to maximize your training experience. You'll engage in live projects and gain practical insights into daily operations, contributing significantly to your professional growth and understanding of industry
                            standards.
                        </p>
                        <p>
                            Our program is designed to enhance essential workplace skills, improve problem-solving abilities, and help you adapt to various work environments. With flexible learning options, you benefit from a mix of online coursework and hands-on practice.
                        </p>
                        <p>
                            The inclusion of practical projects and personalized feedback, allowing you to apply your learning in real-world scenarios and build a strong foundation for your career.
                        </p>
                        <h6 className=' my-4 text-2xl fw-semibold '>
                            Who is it for?</h6>
                        {
                            who.map((obj, index) => (
                                <div>
                                    <h6 className='text-xl  ' > {obj.heading} </h6>
                                    <p> {obj.para} </p>
                                </div>
                            ))
                        }
                        <h6 className=' my-4 text-2xl fw-semibold '>
                            Why choose our Inplant Training program?</h6>
                        {
                            why.map((obj, index) => (
                                <div>
                                    <h6 className='text-xl  ' > {obj.heading} </h6>
                                    <p> {obj.para} </p>
                                </div>
                            ))
                        }


                    </div>
                </section>
                <section className='col-lg-4 col-xl-3 '>
                    <RightSideBlog />
                </section>
            </main>
            {/* <EventsNews /> */}
            <Blogs />
            <Footer/>
        </div>
    )
}

export default InplantTraining