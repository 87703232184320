import React from 'react'

const TwitterIcon2 = ({ size }) => {
    return (
        <svg width={size ? size : "37"} height={size ? size : "37"} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="white" stroke-width="1.5" />
            <path d="M24.2202 15.2647C24.139 15.1729 24.0081 15.1498 23.9007 15.2064C23.8339 15.2416 23.7083 15.2878 23.5592 15.3334C23.7473 15.0762 23.8985 14.7882 23.9653 14.5293C23.9942 14.4177 23.952 14.3001 23.8601 14.2336C23.7682 14.1677 23.6458 14.1677 23.5539 14.2336C23.4091 14.3375 22.7102 14.6497 22.2683 14.7508C21.2696 13.8511 20.1074 13.7577 18.8992 14.4831C17.9171 15.0729 17.7034 16.2721 17.7451 17.0031C15.4971 16.7805 14.0998 15.5593 13.3132 14.5485C13.2582 14.4776 13.1695 14.4403 13.0861 14.4458C12.9985 14.4524 12.9194 14.5024 12.8745 14.5799C12.5026 15.2257 12.3995 15.9396 12.5774 16.6447C12.6747 17.0295 12.8446 17.3686 13.0418 17.6434C12.9472 17.5956 12.8558 17.5357 12.7698 17.4642C12.6907 17.3972 12.5801 17.3845 12.4866 17.429C12.3936 17.4747 12.3343 17.5714 12.3343 17.6775C12.3343 18.8882 13.0706 19.6962 13.7583 20.1183C13.6472 20.1045 13.5312 20.0792 13.4142 20.0424C13.3138 20.0111 13.2048 20.0424 13.1353 20.1238C13.0658 20.2046 13.0493 20.32 13.0926 20.4184C13.4789 21.2972 14.2109 21.9017 15.1166 22.121C14.3258 22.5986 13.2673 22.8328 12.298 22.7179C12.1724 22.6998 12.0527 22.7817 12.0132 22.9059C11.9736 23.0301 12.0255 23.1664 12.1361 23.2301C13.6066 24.0793 14.936 24.3827 16.0849 24.3827C17.7568 24.3827 19.0472 23.7407 19.8327 23.2038C21.9503 21.7583 23.2701 19.163 23.0863 16.841C23.4256 16.5832 23.9333 16.1072 24.248 15.5955C24.3132 15.4922 24.3014 15.3559 24.2202 15.2647Z" fill="white" />
        </svg>

    )
}

export default TwitterIcon2