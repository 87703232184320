import React from 'react'
import CircleFillIcon from '../../Icons/CircleFillIcon'

const HeadingTag = ({ txt, clr }) => {
    return (
        <div className={`w-fit px-3 flex items-center gap-3 text-[#${clr}] `} >
            <div className={` bg-[#${clr}] rounded-full  `} >
                <CircleFillIcon size={10} />
            </div>

            <span> {txt} </span>
            <div className={` bg-[#${clr}] rounded-full  `} >
                <CircleFillIcon size={10} />
            </div>
        </div>
    )
}

export default HeadingTag