import React, { useRef, useState } from 'react'
import StarIcon from '../../Icons/StarIcon'
import HeadingTag from '../MiniCom/HeadingTag'

const TestimonialTCR = ({ page, bg ,content}) => {
    let scrollRef = useRef()
    let [selectedTestimonial, setSelectedTestimonial] = useState(0)
    let testimonial = [
        {
            name: 'Pradeep',
            content: ` As an MBA graduate, I wanted to add digital marketing skills to advance my career and offer freelance services. SkillLearningAcademy was the perfect choice! My trainer,
                                Mr. Karthik, was an expert,
                                sharing practical insights and teaching effective strategies.`,
            img: '../../Assest/cmnt2.jpg'
        },
        {
            name: ' Pragathi S',
            content: ` I’ve wanted to learn digital marketing for a while, and SkillLearningAcademy made it possible. The on-the-job training gave me hands-on experience, and the
                                projects helped me apply what I learned in real-world situations.`,
            img: '../../Assest/cmnt1.jpg'
        },
        {
            name: 'Syed Abdul Fathah',
            content: ` I’ve always been passionate about digital marketing and wanted to dive deeper into it.
                                Thanks to SkillLearningAcademy and their digital marketing course, I was able to do just that.`,
            img: '../../Assest/abdulcomnt.jpg'
        },
        {
            name: 'Ashwin',
            content: ` As an MBA graduate, I wanted to add digital marketing skills to advance my career and offer freelance services. SkillLearningAcademy was the perfect choice! My trainer,
                                Mr. Karthik, was an expert,
                                sharing practical insights and teaching effective strategies.`,
            img: '../../Assest/ashwin.jpg'
        },
        {
            name: ' Akila ',
            content: ` I’ve wanted to learn digital marketing for a while, and SkillLearningAcademy made it possible. The on-the-job training gave me hands-on experience, and the
                                projects helped me apply what I learned in real-world situations.`,
            img: '../../Assest/akila.jpg'
        },
    ]
    return (
        <div className={` ${bg ? bg : "bg-black"} py-5 relative `} >
            <img src={require('../../Assest/patternBG.png')} alt="Pattern"
                className='absolute top-0  h-full w-full ' />
            <main className='col-12 row px-2 col-sm-11 mx-auto ' >
                <div className='relative ' >
                    <p className='text-center poppins  text-3xl w-fit mx-auto fw-semibold  ' >
                        <HeadingTag txt='What Our Students Have to Say' clr='4169FF' /> </p>
                    <p className='poppins text-slate-50 w-[90%] lg:w-[80%] my-3 mx-auto text-center ' >
                       { content?content: ` Hear from our students who have taken the leap, successfully transitioned into permanent roles after
                        completing our program and are now excelling in their chosen careers.`}
                    </p>
                </div>
                <section ref={scrollRef} className={` flex gap-3 ${page ? '  ' : ' col-lg-9'} scrollhide overflow-x-scroll my-4 `} >
                    {testimonial.map((val, index) => (
                        <div onClick={() => setSelectedTestimonial(index)}
                            className={`rounded-xl duration-500 relative 
                            ${selectedTestimonial == index ? '  min-w-[20rem] sm:min-w-[25rem] max-w-[27rem] ' : ' flex-[1] min-w-[4rem] sm:min-w-[11rem] max-w-[13rem] '} 
                             h-[27rem] bg-red-300`} >
                            <img src={val.img ? val.img : require('../../Assest/Maskdummyblog.png')} alt="TestimonialImage"
                                className='h-full w-full object-cover rounded-xl' />
                            <div className={`absolute overflow-hidden p-3 bg-gradient-to-t from-slate-900/80
                                 to-slate-50/0 h-full w-full flex top-0 rounded-xl  `} >
                                <div className='mt-auto inter text-slate-50 ' >
                                    <p className={`${selectedTestimonial == index ? '' : 'line-clamp-1'} text-3xl fw-semibold my-2 `} >
                                        {val.name} </p>
                                    <div className='d-none d-sm-flex text-[#FED815] flex gap-2 items-center mb-3 ' >
                                        {
                                            [1, 2, 3, 4, 5].map((val) => (
                                                <StarIcon />
                                            ))
                                        }
                                    </div>
                                    <p className={`${selectedTestimonial == index ? '' : 'max-h-0 '} overflow-hidden duration-300 text-sm `} >
                                        {val.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}

                </section>
            </main>
        </div>
    )
}
export default TestimonialTCR