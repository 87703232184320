import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import NavBar from '../Component/NavBar'
import Footer from '../Component/Footer'

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className=' poppins ' >
      <NavBar theme='dark' />
      <> <Helmet>
        <title> SkillLearningAcademy Term and Conditions with Company Policies </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Our Terms and Conditions outline the rules, privacy policies, usage, intelctual property, service agreement and regulations governing the use of our website." />
        <link rel="canonical" href={`${domain}/terms-and-condition`} />
      </Helmet>
        <h1 className='d-none'>company term and condition</h1>
        <h2 className='d-none'>company privacy policies</h2>
      </>
      {/* <TitleBanner name="Terms & Condition" /> */}
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">Terms & Conditions</h1>
        <p className="text-sm text-gray-600 mb-6">Last Updated: February 01, 2025</p>
        <p>
          Welcome to <span className=' fw-semibold ' >
            Merida Skill Learning Academy Pvt. Ltd.!  </span>  We are dedicated to providing quality training and courses to help you learn and grow. Please read these Terms and Conditions carefully
          before enrolling in any of our courses or training sessions. By enrolling, you agree to follow these rules.
        </p>
        <section className="mb-6">
          <h2 className="text-xl font-semibold">Agreement to These Terms</h2>
          <ul className='list-disc ' >
            <li>By enrolling and participating in our training programs, you agree to be bound by these Terms & Conditions.</li>
            <li>If you disagree with any part of these terms, please refrain from enrolling in our training programs or courses.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Course Delivery Process</h2>
          <p>
            Our process ensures transparency and accountability from the start
          </p>
          <ul className="list-disc pl-6">
            <li>We begin by aligning your learning objectives with our training programs through a detailed initial consultation.</li>
            <li>Upon enrollment and receipt of payment, your learning journey commences with an orientation session that outlines all course details, schedules, and guidelines</li>
            <li>You are expected to attend all scheduled classes and complete assignments, quizzes, and projects, ensuring a rigorous and structured learning experience.</li>
            <li>Your progress is systematically evaluated through periodic assessments and tests, which help us monitor your academic growth.</li>
            <li>A final project or examination is conducted to confirm your mastery of the course material.</li>
            <li>Upon successful completion, you are awarded a certificate and provided with ongoing career support to facilitate your professional advancement.</li>
            <li>To apply for a hard copy of your certification, a fee of Rs. 500 must be paid. Soft copies of certificates are provided free of charge.</li>
            <li>All courses adhere to a clearly defined schedule with a predetermined start date, ensuring a consistent and efficient learning process.</li>
          </ul>
          <p>
            <span className=' fw-semibold ' >
              Support Hours:  </span>  Our team is available Monday to Saturday from 10:00 AM to 5:30 PM (local time). Response times may be longer on holidays.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Student Responsibilities</h2>
          <p>To ensure you get the best learning experience, you must:</p>
          <h3 className="text-lg font-semibold">Attendance & Punctuality</h3>
          <ul className="list-disc pl-6">
            <li> <span className='fw-semibold ' > Mandatory Attendance: </span>
              A minimum of <span className='fw-semibold '>  90% attendance </span> is required. Regular attendance helps you understand the material
              better and keeps you on track.
            </li>
            <li> <span className='fw-semibold ' >Punctuality :</span> Arrive on time for every class and scheduled session.
              The Academy is not liable for travel or accommodation costs incurred by students.
            </li>
            <li> <span className='fw-semibold ' >Absences :</span> If you miss 7 consecutive days of classes or training without prior intimation, a penalty of 25% of the course fee will be charged. For absences with proper intimation, we may consider planning special sessions to cover missed material,
              but there will be no guarantee of extra classes without prior notice.
            </li>
            <li> <span className='fw-semibold ' >Missed Assessments :</span>  If a student misses weekly assessments without prior notice, a fee of Rs. 1,500
              will be charged to attend the final examination or project evaluation.
            </li>
            <li>  <span className='fw-semibold ' >  Course Reschedule</span>
              <ul className=' list-circle ' >
                <li>Requests for rescheduling due to
                  compassionate or medical reasons will be considered on a case-by-case basis. Supporting documentation may be required.</li>
                <li>For medical-related absences, a valid medical certificate must be provided.
                  We may reschedule without a fee or provide a credit valid for 3 months towards another course.</li>
              </ul>
            </li>
          </ul>

          <h3 className="text-lg font-semibold">Academic Performance</h3>
          <ul className='list-disc ' >
            <li> <span className='fw-semibold ' >Assignments & Projects:</span> You must achieve at least a <span className='fw-semibold '>  70% overall score </span>
              on all assignments, tests, and projects.
              This helps ensure you are grasping the course material.
            </li>
            <li> <span className='fw-semibold ' >Active Participation :</span> Engage in class discussions, complete assignments on time,
              and ask questions when in doubt.
            </li>
          </ul>
          {/* Behaviour */}
          <h3 className="text-lg font-semibold">Behaviour and Discipline</h3>
          <ul className='list-disc ' >
            <li> <span className='fw-semibold ' >Respectful Conduct:</span> Treat instructors and fellow students with respect at all times. Any form of disruptive behavior, misconduct, or misuse of
              course/training materials will lead to disciplinary action, which may include termination from the course.
            </li>
            <li> <span className='fw-semibold ' >Substance Abuse :</span> The use of drugs, intoxicants, or any form of substance abuse is strictly prohibited within the academy premises.
              Any such activity will result in immediate termination.
            </li>
            <li> <span className='fw-semibold ' >Academic Honesty :</span> Plagiarism, cheating, or any form of dishonesty is not
              tolerated and will lead to expulsion from the course.
            </li>
            <li>  <span className='fw-semibold ' >Liability for Damages</span>
              <ul className=' list-circle ' >
                <li>If a student causes any damage to the academy’s property, equipment, or resources whether intentional or due to negligence, will be the financial responsibility of the student.
                </li>
                <li>The student must compensate for the repair or replacement costs as determined by the academy.</li>
                <li>In cases of severe damage or repeated offenses,
                  the academy reserves the right to terminate the student’s enrollment without a refund.</li>
              </ul>
            </li>
          </ul>
          {/* Job assistance */}
          <h3 className="text-lg font-semibold">Job Assistance</h3>
          <ul className='list-disc ' >
            <li>
              We will provide support in resume building, interview preparation, and referrals where applicable.
            </li>
            <li> <span className='fw-semibold ' >To be eligible</span> for job assistance, you must achieve a <span className='fw-semibold '>minimum of 70%</span>
              in the tests and assessments provided during the course.
            </li>
            <li> <span className='fw-semibold ' > Provide Accurate Information: </span> Share complete and truthful details to help us personalize our job assistance.
            </li>
            <li> <span className='fw-semibold ' > Cooperation : </span>Follow the guidance provided for resume building, interview practice, and other career-related services.
            </li>
            <li> <span className='fw-semibold ' > Timely Updates : </span>  Inform us promptly if there are any changes to your personal information or availability for job interviews.
            </li>
          </ul>
        </section>
        {/* Payment */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold">Payment & Refund Policy</h2>
          <h3 className="text-lg font-medium">A. Payment Terms</h3>
          <ul className="list-disc pl-6">
            <li> <span className='fw-semibold ' > Advance Payment : </span>
              Full payment is required before the start of any course or training program.
            </li>
            <li> <span className='fw-semibold ' > Payment Methods : </span>
              We accept payments via bank transfer, credit/debit card, or other agreed methods.
            </li>
            <li> <span className='fw-semibold ' > Cheque Bounces : </span>
              A fee of ₹1000 will be charged for any bounced cheque.
            </li>
            <li>  <span className='fw-semibold ' >Late Payments: </span>
              <ul className=' list-circle ' >
                <li>Course fees must be paid in full or as per the agreed installment plan before the due dates.</li>
                <li>Failure to make payments on time may incur additional charges.</li>
              </ul>
            </li>
          </ul>
          <h3 className="text-lg font-medium">B. Refund Policy</h3>
          <p>
            We understand that situations can change. Please review our refund terms carefully:
          </p>
          <ul className="list-disc pl-6">
            <li> <span className='fw-semibold ' >Cancellation before course commencement : </span>
              If you choose to cancel before the commencement of the course, you will be eligible for a <span className='fw-semibold '> 50% refund  </span>
              of the total course fee.
            </li>
            <li> <span className='fw-semibold ' > Cancellation within 3 Days of batch start date : </span>
              If you cancel within 3 days from the payment date, you will receive a <span className='fw-semibold '>  25% refund </span>
              of the total course fee.
            </li>
            <li> <span className='fw-semibold ' > No Refund After 3 Days   : </span>
              No refunds will be provided if cancellation occurs after 3 days from the start of the course or training program.
            </li>
            <li> <span className='fw-semibold ' > Partial Course Completion : </span>
              If any portion of the course has already been completed, the refund amount may be adjusted accordingly.
            </li>
            <li> <span className='fw-semibold ' > Processing Time : </span>
              Refunds, if applicable, will be processed within 15 business days and will be issued using the same payment method used for the original transaction.
            </li>
          </ul>
          <h3 className="text-lg font-medium">C. Pricing Disclaimer</h3>
          <ul>
            <li> <span className='fw-semibold ' >Pricing Changes : </span>
              Prices for our courses and services may change at any time due to market conditions or other reasons.
            </li>
            <li> <span className='fw-semibold ' >Your Price is Guaranteed : </span>
              If you’ve already paid, the price you paid is the price you’ll stick with, even if the price changes later.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Data Protection and Privacy</h2>
          <ul>
            <li> <span className='fw-semibold ' >Your Information : </span>
              Any personal information you share with us will be kept private and used only to manage your enrollment and provide course materials
            </li>
            <li> <span className='fw-semibold ' >Security : </span>
              We take steps to protect your information from unauthorized access.
            </li>
          </ul>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold"> Modification of Terms </h2>
          <p>We reserve the right to modify these terms at any time. If a change is material,
            we will notify you at least 30 days before the new terms take effect. Continued use of our
            services means you accept the new terms.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold">Dispute Resolution </h2>
          <p>If any disagreement or issue arises, both parties will first attempt to resolve it amicably through discussions.
            If unresolved, the dispute will be referred to arbitration under the <span className='fw-semibold ' >
              Arbitration and Conciliation Act, 1996, </span>  with
            proceedings held in <span className='fw-semibold ' >   Bangalore, Karnataka.  </span> If arbitration fails, the courts of Bangalore will have exclusive jurisdiction
            only.
            This process ensures that both sides are treated fairly, and any disputes are resolved efficiently and lawfully.

          </p>
        </section>
        <section className='mb-6 ' >
          <h2 className="text-xl font-semibold" > Disclaimer  </h2>
          <p>
            ALL COURSES AND TRAINING PROGRAMS PROVIDED BY SKILL LEARNING ACADEMY ARE OFFERED "AS IS" AND "AS AVAILABLE." NO GUARANTEES OR WARRANTIES ARE MADE REGARDING SPECIFIC OUTCOMES OR PERFORMANCE. WHILE WE STRIVE TO DELIVER HIGH-QUALITY TRAINING AND EDUCATIONAL CONTENT, WE DO NOT PROMISE THAT YOU WILL ACHIEVE ANY PARTICULAR RESULT, SUCH AS JOB PLACEMENT, SKILLS MASTERY, OR CAREER ADVANCEMENT. THE SUCCESS YOU EXPERIENCE DEPENDS ON YOUR OWN EFFORTS, DEDICATION, AND VARIOUS EXTERNAL FACTORS BEYOND OUR CONTROL.
            BY ENROLLING IN OUR COURSES OR TRAINING PROGRAMS, YOU AGREE THAT ANY RELIANCE ON THE EDUCATIONAL CONTENT, GUIDANCE, OR SUPPORT PROVIDED IS SOLELY AT YOUR OWN RISK. UNDER NO CIRCUMSTANCES SHALL SKILL LEARNING ACADEMY, ITS OWNERS, OR ITS STAFF BE HELD LIABLE FOR ANY LOSS, DAMAGE, OR UNFAVORABLE OUTCOMES THAT MAY ARISE FROM YOUR PARTICIPATION.
            WE RESERVE THE RIGHT TO TERMINATE OR SUSPEND ANY SERVICE, COURSE, OR TRAINING PROGRAM AT ITS DISCRETION, AT ANY TIME, WITHOUT PRIOR NOTICE. IN THE EVENT OF SUCH TERMINATION OR SUSPENSION, WE WILL WORK TO OFFER AN ALTERNATIVE COURSE OR PROVIDE A PARTIAL REFUND, WHERE APPLICABLE. NO REFUNDS OR COMPENSATION WILL BE PROVIDED UNLESS SPECIFICALLY STATED OTHERWISE.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold">Contact Us</h2>
          <p>
            If you have any questions or need support, feel free to get in touch with us anytime!
          </p>
          <h5> For Important Matters & Concerns </h5>
          <p> <span className='fw-semibold ' > Email :  </span>  <a href="mailto:info@skilllearningacademy.com" className="text-blue-500">info@skilllearningacademy.com</a></p>
          <p>  <span className='fw-semibold ' > Address :  </span>  20-2, Keshava Krupa Building, 2nd Floor,<br /> 30th Cross, 10th Main Rd, 4th Block,<br /> Jayanagar, Bengaluru, Karnataka - 560011</p>
          <p>
            Thank you for choosing Skill Learning Academy – We’re here to help you learn and grow!
          </p>
        </section>
      </div>
      <Footer/>
    </div>
  )
}

export default TermsAndCondition