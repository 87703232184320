import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { domain, hostname } from '../App'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { SlaData } from '../Context/SLAstore'
import TreandyPost from '../Component/Blog/TreandyPost'
import Allpost from '../Component/Blog/Allpost'
import SubjectSlides from '../Component/Blog/SubjectSlides'
import PopularPost from '../Component/Blog/Popularpost'
import LatestPost from '../Component/Blog/LatestPost'
import SearchIcon from '../Icons/SearchIcon'
import NavBar from '../Component/NavBar'
import Footer from '../Component/Footer'

const Blog = () => {
    let [allBlog, setAllBlog] = useState()
    let { convertDate } = useContext(SlaData)
    let [recentBlog, setRecentBlog] = useState()
    let [popularPost, setPopularPost] = useState()
    let [filterWord, setFilterWord] = useState('')
    let [filteredArray, setFilteredArray] = useState()
    useEffect(() => {
        axios.get(`${hostname}/blog/BlogViewSet/`).then((response) => {
            setAllBlog(response.data)
            setRecentBlog(response.data.reverse()[0])
            console.log(response.data, 'blog', `${hostname}/blog/BlogViewSet/`);
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    let navigate = useNavigate()
    let filterSearch = () => {
        let lw = filterWord.toLowerCase()
        if (lw == '') {
            setFilteredArray(null)
        }
        else if (allBlog && lw) {
            let filterArray = [...allBlog].filter((obj) => obj.Main_Title && obj.Main_Title.toLowerCase().indexOf(lw) != -1)
            console.log(filterArray, 'filter');
            if (filterArray && filterArray.length > 0)
                setFilteredArray(filterArray)
            else
                setFilteredArray([])
        }
        else {
            setFilterWord(null)
        }



    }
    return (
        <>
            <NavBar theme='dark' />
            <div className='container mx-auto row py-5 col-xl-10'>
                <> <Helmet>
                    <title>Our Blog Gives You Many Informations on Particular Topic.    </title>
                    <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
                    <link rel="canonical" href={`${domain}/blogs`} />
                </Helmet>
                    <h1 className='d-none'>informative blog content </h1>
                    <h2 className='d-none'>best blogging tips and tricks </h2>
                </>

                {/* All Contents */}
                <div className='col-lg-8 col-xl-8 h-[125vh]  scrollbar overflow-y-scroll'>
                    {/* Section 1 */}
                    {recentBlog &&
                        <div onClick={() => navigate(`/blogs/${recentBlog.slug}`)}
                            className='relative cursor-pointer inter rounded '>
                            <img src={recentBlog.img ? recentBlog.img : require('../Assest/Maskdummyblog.png')} className='w-full ' loading='lazy' alt="Blog Image" />
                            <section className=' absolute w-full h-full top-0 bg-slate-900 bg-opacity-20 '>
                                <div className='w-4/5 absolute bottom-1 text-xs left-5 rounded px-3 '>
                                    <p className='mb-0 text-sm sm:text-xl fw-bold lg:text-2xl fw-medium'>{recentBlog.Main_Title} </p>
                                    {/* <div className='flex gap-3 items-center'>
                                    <p className='flex gap-2 items-center'><span className='text-blue-600'> <CalenderIcon /> </span> {convertDate(recentBlog.created_at.slice(0, 10))}  </p>
                                    <p className='flex gap-2 items-center'><span className='text-blue-600'> <CommentIcon /> </span> 05 Comments  </p>
                                </div> */}
                                    <p className='d-none d-sm-block sm:text-sm'>
                                        {recentBlog.Paragraph1.slice(0, 100)}
                                        {recentBlog.Paragraph1.length > 100 && "..."}
                                    </p>
                                    <p className=' d-sm-none'>{recentBlog.Paragraph1.slice(0, 50)}
                                        {recentBlog.Paragraph1.length > 50 && "..."} </p>

                                </div>
                            </section>
                        </div>}
                    {/* Subject slides */}
                    <p className='fw-medium text-2xl flex items-center mt-3 gap-2 '> <span className='p-[3px] bg-blue-500 rounded-full h-[12px] w-[1px] '>
                    </span>
                        Digital Marketing </p>
                    <SubjectSlides data={allBlog} name="blog" />
                    <TreandyPost />
                    <Allpost />
                </div>
                {/* Filter sticky */}
                <section className='col-lg-4 col-xl-4 '>
                    <div className='relative rounded w-full bg-slate-50 p-3'>
                        <div className='rounded flex w-full border-1 bg-violet-200 bg-opacity-30'>
                            <input value={filterWord} onChange={(e) => setFilterWord(e.target.value)} onKeyDown={(e) => {
                                if (e.key == 'Enter')
                                    filterSearch()
                            }}
                                type="text" className='w-full outline-none border-0 bg-transparent h-full rounded p-1' placeholder='Search' />
                            <button onClick={() => filterSearch()} className='p-[10px] rounded bg-violet-800 text-white'>
                                <SearchIcon /> </button>
                        </div>
                        {/* Search result */}
                        {filteredArray &&
                            <section className='h-[20vh] w-full absolute bg-slate-100 shadow-sm top-20 py-3 overflow-y-scroll scrollbar rounded  left-0  ' >
                                {
                                    filteredArray.length > 0 ? filteredArray.map((obj) => (
                                        <div onClick={() => navigate(`/blogs/${obj.slug}`)} className='border-b-2 text-sm line-clamp-1 py-1 px-3 hover:bg-slate-200 cursor-pointer ' >
                                            {obj.Main_Title}
                                        </div>
                                    )) : <div>
                                        <p className='text-sm px-3' > No Matching Result found </p>
                                    </div>
                                }
                            </section>}
                    </div>



                    {allBlog && <LatestPost data={allBlog} />}
                    <PopularPost />
                    <div style={{ backgroundColor: 'rgb(247,247,249)' }} className='my-2 p-3 rounded '>
                        <p className='text-black text-lg fw-semibold '>Popular Tag</p>
                        <div className='flex gap-2 flex-wrap'>
                            {
                                ['Education', 'Training', 'Online', 'Learn', 'Course', 'LMS'].map((x) => {
                                    return (
                                        <p className='p-1 text-violet-800 mb-0 px-2 bg-white'>{x} </p>
                                    )
                                })
                            }

                        </div>
                    </div>
                </section>

            </div>
            <Footer/>
        </>
    )
}

export default Blog