import React, { useEffect, useState } from 'react'
import HeadingTag from '../MiniCom/HeadingTag'

const ExclusiveFeature = ({ content }) => {
    let [features, setFeatures] = useState([
        {
            css: 'col-sm-6',
            title: 'Start Training with an Offer Letter',
            content: `No more waiting or job hunting! Get your offer letter on day one and start learning while already being part of the company.`,
            img: '../../Assest/startTraining.png',
            bgclr: 'bg-[#FFD8D8]'
        },
        {
            css: 'col-sm-6',
            title: 'Project-Based Learning',
            content: `Earn real experience instead of just learning theories in a classroom. Work on projects that teach you exactly how things work, so you’ll feel confident and ready for any job.`,
            img: '../../Assest/projectBased.png',
            bgclr: 'bg-[#FFEFD8]'
        },
        {
            css: 'col-sm-4',
            title: 'Work-Integrated Training',
            content: `Get practical experience from the start. With work integrated into your training, you’ll apply your lessons to real tasks, ensuring you’re job-ready by the end.`,
            img: '../../Assest/workIntegrated.png',
            bgclr: 'bg-[#EBFFD8]'
        },
        {
            css: 'col-sm-4',
            title: 'Industry-Specific Tools & Techniques',
            content: `No outdated methods! Train with the latest tools and techniques used by top companies, so you know exactly what’s needed in today’s job market.`,
            img: '../../Assest/industry.png',
            bgclr: 'bg-[#D8FFDC]'
        },
        {
            css: 'col-sm-4',
            title: 'Multiple Certificates Upon Project Completion',
            content: `Showcase your hard work with certificates earned after completing real-world projects, boosting your resume and proving you’ve got the experience employers are looking for.`,
            img: '../../Assest/multiplecertificate.png',
            bgclr: 'bg-[#D8DEFF]'
        },
        {
            css: 'col-sm-6',
            title: 'Exposure to Prompt Engineering & AI Tools',
            content: `Learn how to use the latest tech like AI tools and prompt engineering to stay ahead of the curve and add cutting-edge skills to your resume.`,
            img: '../../Assest/promtEngineering.png',
            bgclr: 'bg-[#D8DEFF]'
        },
        {
            css: 'col-sm-6',
            title: 'Networking with Experts',
            content: `Meet professionals who’ve been where you are! Connect with industry experts, mentors, and peers who can guide, support, and help you grow in your career.`,
            img: '../../Assest/networking.png',
            bgclr: 'bg-[#EBD8FF]'
        },
    ])
    useEffect(() => {
        if (content) {
            setFeatures(content)
        }
    }, [content])
    return (
        <div className='py-4 bg-[#0A0A0A] relative' >
            <img src={require('../../Assest/patternBG.png')} alt="BackgroundPattern"
                className=' absolute z-0 top-0 w-full h-full object-cover ' />
            <h4 className='w-fit mx-auto poppins text-3xl fw-semibold my-3 ' >
                <HeadingTag txt='Exclusive Features' clr='FFFFFF' />
            </h4>
            {/* Main section */}
            <main className='row col-12 relative px-2 col-sm-11 mx-auto ' >
                {
                    features.map((obj, index) => (
                        <section className={`my-3 ${obj.css} `} >

                            <div className={` ${obj.bgclr} items-center rounded p-3 h-full flex gap-2 `} >
                                <img src={obj.img ? obj.img : require('../../Assest/Maskdummyblog.png')} alt="Image"
                                    className={` w-[40%] h-[16vh] rounded object-cover`} />
                                <div className='poppins w-[60%] ' >
                                    <h6 className=' fw-medium my-2 ' > {obj.title} </h6>
                                    <p className='text-xs ' >
                                        {obj.content}
                                    </p>
                                </div>
                            </div>
                        </section>
                    ))
                }
            </main>
        </div>
    )
}

export default ExclusiveFeature