import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { domain, hostname } from '../App'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import MailIcon from '../Icons/MailIcon'
import PhoneIcon from '../Icons/PhoneIcon'
import LocationIcon from '../Icons/LocationIcon'
import ReCAPTCHA from 'react-google-recaptcha'
import NavBar from '../Component/NavBar'
import Footer from '../Component/Footer'

const ContactPage = () => {
    const latitude = 12.92875;
    const longitude = 77.5853652;
    let [errorMessage, setErrormessage] = useState('')
    const [contactForm, setContactForm] = useState({
        email: '',
        message: '',
        first_name: '',
        last_name: '',
        phone: '',
        captcha: false
    })
    let [loading, setloading] = useState('')
    let handlechange = (e) => {
        let { name, value } = e.target
        if (name == 'phone' && value.length > 10)
            value = contactForm.phone
        setContactForm((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(contactForm.email) && contactForm.phone != '' && contactForm.first_name != '' && contactForm.message != '') {
            setErrormessage('')
            return true
        }
        else if (!emailRegex.test(contactForm.email) && contactForm.phone == '' && contactForm.first_name == '' && contactForm.message == '') {
            setErrormessage('*Enter all the required inputs')
        }
        else if (!emailRegex.test(contactForm.email)) {
            setErrormessage("Enter the proper mail and other inputs")
        }
        else {
            setErrormessage('*Enter all the required inputs')
        }
    }
    let postContact = () => {
        if (contactForm.captcha) {
            if (validateForm()) {
                setloading('contact')
                axios.post(`${hostname}/blog/ContactsInformation/`, contactForm).then((response) => {
                    console.log(response.data);
                    toast.success('Your Message has been sended, Our team will get touch in short time.')
                    setloading('')
                    setContactForm({
                        email: '',
                        message: '',
                        first_name: '',
                        last_name: '',
                        phone: ''
                    })
                }).catch((error) => {
                    console.log(error);
                    setloading('')
                })
            }
        }
        else {
            setErrormessage('*Check the Captcha')

        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='relative bgsec py-0'>
            <NavBar theme='dark' />
            <> <Helmet>
                <title>Contact Us for Your Learning Needs and Other Requirements </title>
                <meta name="description" content="We are here to assist you with any questions and concerns for any inquiries, support or feedback through our dedicated team on phone, emails or office address." />
                <link rel="canonical" href={`${domain}/contact-us`} />
            </Helmet>
                <h1 className='d-none'>Contact us for queries</h1>
                <h2 className='d-none'>Assistance for your questions </h2>
            </>

            {/* <img style={{ zIndex: -1 }} className='w-full top-0 absolute ' loading='lazy' src={require('../Assest/contactBanner.png')} alt="" /> */}
            <div style={{ zIndex: 2 }} className='z-2 py-5 text-center '>
                <h6 className='text-center text-blue-500 inter uppercase fw-normal'> Contact us </h6>
                <h6 className='fw-bold text-3xl sm:text-5xl my-3 poppins'>Have any queries? We’re all ears!</h6>
                <p className='poppins px-1 '>Our team is trained, equipped & ready to guide you from scratch to success. </p>
            </div>
            <div className='sm:h-[30px]'>

            </div>
            {/* Form */}
            <div style={{ zIndex: 2 }} className='row container mx-auto   rounded-3xl min-h-[75vh] mt-4 p-0 overflow-hidden
             border-violet-300 border-2 '>
                <section className='col-lg-6 min-h-[40vh] relative p-0'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15554.74188246344!2d77.5848919!3d12.9279258!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15ba4bc9a999%3A0xcf545ed299575195!2sSkill%20learning%20academy%20-%20Job%20Assured%20course!5e0!3m2!1sen!2sin!4v1718090890407!5m2!1sen!2sin" width="600" height="450"
                        className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <a href='https://maps.app.goo.gl/ZXp431Zwzp3r7giH6' target='_blank' className='p-2 absolute top-0 right-0 z-10 rounded-full'>
                        {/* <img src={require('../Assest/map.png')} loading='lazy' alt="Map" width={50} />   */}
                    </a>
                </section>
                <section className='col-lg-6 flex p-5 inter bg-violet-100 items-center justify-center'>
                    <div className=''>
                        <p className='text-3xl fw-semibold inter'>Let's connect constellations</p>
                        <p className='text-slate-500 w-5/6 text-sm'>Let's align our constellations! Reach out and let the magic of collaboration illuminate our skies.</p>
                        {/* Form  */}
                        <div className=''>
                            <div className='flex gap-3 mb-3 flex-col sm:flex-row justify-between'>
                                <input type="text" value={contactForm.first_name} name='first_name' onChange={handlechange} placeholder='First name *' className='rounded w-full border-2 outline-none border-sky-200 p-2' />
                                <input type="text" value={contactForm.last_name} name='last_name' onChange={handlechange} placeholder='Last name' className='rounded w-full border-2 border-sky-200 outline-none p-2' />
                            </div>
                            <input type="email" value={contactForm.email} name='email' onChange={handlechange} placeholder='Email *' className='rounded w-full border-2 border-sky-200 outline-none p-2 mb-3' />
                            <input type="number" value={contactForm.phone} name='phone' onChange={handlechange} placeholder='Phone *' className='rounded w-full noarror border-2 border-sky-200 outline-none p-2 mb-3' />
                            <textarea value={contactForm.message} name='message' onChange={handlechange} id="" cols="30" rows="6" placeholder='Message *' className='rounded resize-none w-full noarror border-2 border-sky-200 outline-none p-2 mb-3'></textarea>
                            <div className='p-2 flex items-center justify-center'>
                                <ReCAPTCHA
                                    className=' md:scale-[0.90] inputbg '
                                    spellCheck={contactForm.captcha}
                                    sitekey="6Ledg_QpAAAAAKgVH6WcItGafyMHUtoShRHFmMVc"
                                    onChange={() => {
                                        setContactForm((prev) => ({
                                            ...prev,
                                            captcha: !contactForm.captcha
                                        }))
                                    }}
                                />
                            </div>
                            <p className='text-red-600 text-center'> {errorMessage} </p>
                            <button disabled={loading == 'contact'} onClick={postContact}
                                className='bannerbg rounded p-2 w-full my-1 text-white'>   {loading == 'contact' ? 'loading' : "Send Message"}</button>
                        </div>
                    </div>
                </section>
            </div>
            {/* Cards */}
            <div className='container flex gap-3 my-20 justify-center sm:justify-between flex-wrap mx-auto '>
                <div style={{ boxShadow: '1px 3px 24px #e5e5e5 ' }} className='w-[20rem] xl:w-[23rem] sm:h-[10rem] flex gap-3 items-center -sm inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-500 rounded-full '>
                        <LocationIcon size='20' />
                    </div>
                    <div className=''>
                        <p className='fw-semibold m-0 '> Address</p>
                        <p className='text-slate-500'>  20-2 ,Keshava Krupa Building 2nd Floor, 30th cross, 10th Main Rd, 4th Block, Jayanagar, Bengaluru, Karnataka 560011
                        </p>
                    </div>
                </div>
                <div style={{ boxShadow: '1px 3px 24px #e5e5e5 ' }} className='w-[20rem] xl:w-[23rem] h-[10rem] flex gap-3 items-center  inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-500 rounded-full '>
                        <PhoneIcon size='20' />
                    </div>
                    <div>
                        <p className='fw-semibold m-0 '> Phone</p>
                        <p className='text-slate-500 m-0'>+91 8904599282 </p>
                        <p className='text-slate-500'>+91 9876543212 </p>
                    </div>
                </div>
                <div style={{ boxShadow: '1px 3px 24px #e5e5e5 ' }} className='w-[20rem] xl:w-[23rem] h-[10rem] flex gap-3 items-center inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-500 rounded-full '>
                        <MailIcon size='20' />
                    </div>
                    <div>
                        <p className='fw-semibold m-0 '> E-Mail Address</p>
                        <p className='text-slate-500 text-xs xl:text-sm  '> info@meridatechminds.com</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ContactPage